import Vue from "vue";

export default {
    async checkIfValid(code, token) {
        const response = await Vue.axios.get(
            `shop/promoCodes/isValid?code=${code}&access_token=${token}`
        );
        return response.data;
    },
    async createSubscriptionPromo(code, token) {
        const response = await Vue.axios.post(
            `/shop/promoCodes/usePromoCode?code=${code}&access_token=${token}`
        );
        return response.data;
    },
};
