<template>
  <v-container>
    <v-row class="layout-2col links-underline wrapper">
      <v-col class="descCol">
        <h3>Prosimo, vpišite promocijsko kodo</h3>
        <hr class="shortGray"/>
        <p>
          Uživajte v najbogatejšem programskem paketu TV Maxi z vključenim časovnim
          zamikom za 7 dni nazaj in funkcijo Modri gumb z označenimi TOP dogodki tekem -
          naj bo to odločilni gol, cilj kolesarske etape ali nov svetovni rekord.
        </p>
        <br/>
        <v-btn
          class="orangeButtonL centerPosition"
          color="primary"
          depressed
          @click="changePaymentType"
        >
          Nimam promocijske kode
        </v-btn>
      </v-col>
      <v-col class="selectCol">
        <div class="promoFlow">
          <div class="flowBoxBlue">
            <div class="inputFormFlex">
              <input v-model="code" type="text" placeholder="Moja promocijska koda" @keypress.enter="next"/>
            </div>
          </div>
          <hr class="gradient"/>
          <div class="buttonsFlow">
            <div></div>
            <v-btn color="primary" @click="next">
              Naslednji korak
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import promoCodeService from "../../services/promoCodeService";
import {purchaseTypes} from "@/enumerations/purchaseTypes";

export default {
  name: "PromoCodeStepOne",
  data() {
    return {
      code: null,
    };
  },
  mounted() {
    this.code = this.$store.getters["promoCode/getCode"];
  },
  methods: {
    next() {
      if (this.code === null || this.code === '') {
        return;
      }
      promoCodeService.checkIfValid(this.code, this.$store.getters["auth/getAccessToken"])
        .then(() => {
          this.$store.dispatch('promoCode/setCode', this.code);
          this.$store.dispatch('promoCode/setValid', true);
          return this.$router.push({name: "PromoStep_2"});
        })
        .catch(() => {
          this.$store.dispatch('alert/toggleShow', false);
          this.$store.dispatch('promoCode/setValid', false);
          return this.$router.push({name: "PromoStep_2"});
        });
    },
    changePaymentType() {
      this.$store.dispatch('user/setPurchaseType', purchaseTypes.Regular);
      this.$router.push({name: "Package_select_step_1"});
    }
  },
};
</script>

<style scoped>

</style>
