import Vue from 'vue';

export default {
    async cancel() {
        const response = await Vue.axios.post('/shop/subscriptions/cancelSubscription');
        return response.data;
    },
    async index() {
        const response = await Vue.axios.get('/shop/subscriptions');
        return response.data;
    },
}
