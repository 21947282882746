import Vue from 'vue';

export default {
    async index(subscriberId) {
        const response = await Vue.axios.get('fora/devices/info?subscriberId=' + subscriberId);
        return response.data;
    },
    async store(params) {
        const response = await Vue.axios.post('fora/devices/add', params);
        return response.data;
    },
    async destroy(params) {
        const response = await Vue.axios.post('fora/devices/remove', params);
        return response.data;
    },
}
