<template>
  <div v-if="allPrograms">
    <div class="padding">
      <div class="background">
        <div v-if="isLandingPage" :class="classObject" class="packageIcon"></div>
        <h2>Paket {{ selectedPackage.naziv }}</h2>
        <h3 v-if="allPrograms.channels.length === 0">Samo vaši programi</h3>
        <h3 class="programLength" v-else>{{ allPrograms.channels.length }} programov</h3>
        <hr v-if="!isLandingPage" class="black">

        <div v-if="allPrograms.channels.length === 0" class="customTab">
          <p>V paket si dodajte najljubše programe.</p>
          <hr v-if="!isLandingPage" class="black">
        </div>

        <div v-else>
          <ul class="program-list">
            <li v-for="(n, i) in 5" :key="i">{{ allPrograms.channels[i] }}</li>
          </ul>
          <div v-if="allPrograms.channels.length > 0">
            <ProgramList :channels="allPrograms.channels" :package-name="selectedPackage.naziv"/>
            <hr v-if="!isLandingPage" class="black">
          </div>
        </div>
        <ul class="feature-list">
          <li v-for="(feature, index) in allPrograms.features" :key="index">{{ feature }}</li>
        </ul>

        <div class="sum-price">
          <label>Cena za paket
            <span>po izteku brezplačnega obdobja</span>
          </label>
          <span class="price">{{ selectedPackage.cena }} €
              <span class="month">na mesec</span>
            </span>
        </div>
      </div>
      <v-btn
        v-if="!isLandingPage"
        class="orangeButtonL centerPosition"
        color="primary"
        depressed
        @click="next"
      >
        IZBERITE TA PAKET
      </v-btn>
    </div>
    <!--end-->
  </div>

</template>

<script>
import ProgramList from "@/components/ProgramList";

export default {
  name: "PackageTabItem",
  components: {ProgramList},
  props: {
    selectedPackage: {
      type: Object,
      required: true,
    },
    allPrograms: {
      type: Object,
      default: null
    },
    isLandingPage: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    next() {
      this.$store.dispatch('offer/setSelectedPackage', this.selectedPackage);
      this.$store.dispatch('offer/setTotalPrice', this.selectedPackage.cena);
      this.$store.dispatch('offer/setAdditionalPackages', []);
      this.$store.dispatch('offer/setTimeLapseFunction', null);
      this.$router.push({name: 'Package_select_step_2'});
    }
  },
  computed: {
    classObject: function () {
      return {
        mini: this.isLandingPage && this.selectedPackage.naziv == "T-2 TV Mini",
        midi: this.isLandingPage && this.selectedPackage.naziv == "T-2 TV Midi",
        maxi: this.isLandingPage && this.selectedPackage.naziv == "T-2 TV Maxi",
      }
    }
  }
}

</script>

<style scoped>

</style>
