<template>
  <v-dialog
    v-model="creditCardDialog"
    persistent
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        <!--        <span class="warpit_icon_trash"></span>-->
      </v-btn>
    </template>
    <v-card class="popUp form">
      <div class="popUpHeader">
        <h3 class="margin20">Izbris kartice</h3>
        <a
          class="closeButton"
          @click="creditCardDialog = false"
        >
          <span class="warpit_icon_x"></span>
        </a>
      </div>
      <hr class="gradient">
      <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus facilisis sapien ut risus mollis,
        a faucibus magna suscipit. Vestibulum eu lectus et justo efficitur gravida sit amet sit amet lorem.
      </v-card-text>

      <hr class="gradient">
      <v-card-actions class="buttons">
        <v-btn
          class="primary saveButton"
          text
          @click="creditCardDialog = false"
        >
          Izbriši kartico
        </v-btn>
        <v-btn
          class="cancelButton"
          text
          @click="creditCardDialog = false"
          plain
        >
          Prekličite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreditCardProfile",
  data() {
    return {
      creditCardDialog: false,
    }
  },

}
</script>

<style scoped>

</style>
