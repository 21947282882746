<template>
  <v-container>
    <v-row class="layout-2col links-underline wrapper">
      <v-col class="descCol">
        <h3>Prosimo, vpišite promocijsko kodo</h3>
        <hr class="shortGray"/>
        <p>
          Uživajte kar 3 mesece v najbogatejšem programskem paketu TV Maxi z
          vključenim časovnim zamikom za 7 dni nazaj in funkcijo Modri gumb z
          označenimi TOP dogodki tekem - naj bo to odločilni gol, cilj
          kolesarske etape ali nov svetovni rekord.
        </p>
        <br/>
        <v-btn
          class="orangeButtonL centerPosition"
          color="primary"
          depressed
          @click="changePaymentType"
        >
          Nimam promocijske kode
        </v-btn>
      </v-col>
      <v-col class="selectCol">
        <div class="promoFlow">
          <!-- step 2 if code is correct -->
          <div v-if="isValid">
            <div class="flowBoxBlue">
              <div class="withIcon">
                <div class="textWithIcon">
                  <span class="warpit_icon_check-round icon"></span>
                  <h3>Promocijska koda potrjena</h3>
                </div>
                <p>
                  Za vklop brezplačne uporabe T-2 TV z največjo programsko shemo
                  MAXI in ogledom vsebin do 7 dni nazaj vpišite svoje podatke na
                  naslednjem koraku.
                </p>
              </div>
            </div>
            <hr class="gradient"/>
            <div class="buttonsFlow">
              <router-link :to="{ name: 'PromoStep_1' }" class="underline-hover">Prejšnji korak</router-link>
              <v-btn color="primary" @click="next">
                Naslednji korak
              </v-btn>
            </div>
          </div>
          <!-- step 2 if code is incorrect-->
          <div v-else>
            <div class="flowBoxBlue">
              <div class="withIcon">
                <div class="textWithIcon">
                  <span class="warpit_icon_cancel icon"></span>
                  <h3>Promocijska koda zavrnjena</h3>
                </div>
                <p>
                  Prosimo, preverite ali ste promocijsko kodo pravilno vpisali. Za pomoč in dodatne informacije se lahko obrnete na info@t-2.net.
                </p>
              </div>
            </div>
            <hr class="gradient"/>
            <div class="buttonsFlow">
              <router-link :to="{ name: 'PromoStep_1' }" class="underline-hover">Prejšnji korak</router-link>
              <v-btn color="primary" disabled> Naslednji korak</v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {purchaseTypes} from "@/enumerations/purchaseTypes";

export default {
  name: "PromoCodeStepTwo",
  computed: {
    isValid() {
      return this.$store.getters["promoCode/getValid"];
    },
  },
  methods: {
    next() {
      if (this.isValid) {
        return this.$router.push({name: "PromoStep_3"});
      }
      return this.$router.push({name: "PromoStep_2"});
    },
    changePaymentType() {
      this.$store.dispatch('user/setPurchaseType', purchaseTypes.Regular);
      this.$router.push({name: "Package_select_step_1"});
    }
  },
};
</script>

<style scoped>

</style>
