<template>
  <div class="appSteps">
    <v-container >
      <div class="wrapper noPadding">
        <h1 class="mainTitle">Izbor dodatnih funkcij</h1>
        <hr class="title">
      </div>
      <v-row class="layout-2col wrapper showSideResponsive">
        <v-col class="selectCol L">


          <div class="time-lapse">
            <label>{{timeLapse.naziv}}
              <small>+{{timeLapse.cena}} € / mesec </small></label>
            <v-switch
                v-model="toggleTimeLapsed"
                @change="calculateTotalPrice()"
                :value="timeLapse"
                inset
            ></v-switch>
          </div>

          <div class="record-space">
            <hr class="black sm">
            <label>
              Velikost snemalnika
              <small>Snemalnik omogoča, da lahko svoje najljubše TV vsebine tudi posnamete in si jih ogledate kasneje, ko boste sami želeli. </small>
            </label><br>
            <div class="flex">
              <label v-if="selectedHorizont !== null">{{selectedHorizont.naziv}}
                <small>+{{selectedHorizont.cena}} € / mesec </small>
              </label>
              <label v-else>Brez snemalnika</label>
              <div class="buttonsUpDown">
                <v-btn
                  :disabled="iterationIndex <= -1"
                  class="mx-2 minus"
                  fab
                  small
                  @click="selectedHorizontChange(0)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
                <v-btn
                  :disabled="iterationIndex >= horizont.length - 1"
                  class="mx-2"
                  fab
                  small
                  @click="selectedHorizontChange(1)"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <hr class="marginBottom30">


          <div class="navButtons">
            <router-link :to="{ name: 'Package_select_step_2'}" class="underline-hover">Prejšnji korak</router-link>
            <v-btn
                color="primary"
                depressed
                class="orangeButtonL"
                @click="next"
            >
              Naslednji korak
            </v-btn>
          </div>


        </v-col>
        <v-col class="descCol R">
          <div class="sum">
            <h5 class="sumTitle">POVZETEK NAKUPA</h5>
            <hr/>
            <span class="sectionTitle">IZBRANI PAKET</span>
            <ul class="list">
              <li><span class="package">{{selectedPackage.naziv}}</span> <span class="pricePckage">{{selectedPackage.cena}} €<small>/mesec</small></span></li>
            </ul>
            <hr/>
            <span class="sectionTitle">DODATNI PROGRAMSKI PAKETI</span>
            <ul class="list borders">
              <li v-for="item in selectedAdditionalPackages" :key="item.id">
                <span>{{item.naziv}}</span> <span class="pricePckage">{{item.cena}} €<small>/mesec</small></span>
              </li>
            </ul>
            <hr/>
            <span class="sectionTitle">DODATNE FUNKCIJE</span>
            <ul class="list borders">
              <li v-if="toggleTimeLapsed !== null"><span>{{timeLapse.naziv}}</span> <span class="pricePckage">{{timeLapse.cena}} €<small>/mesec</small></span></li>
              <li v-if="selectedHorizont !== null"><span>{{selectedHorizont.naziv}}</span> <span class="pricePckage">{{selectedHorizont.cena}} €<small>/mesec</small></span></li>
            </ul>
            <hr class="black last"/>
            <ul class="list sum">
              <li><span>Za plačilo</span> <span class="pricePckage">{{price}} €<small>/mesec</small></span></li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import priceService from "../../services/priceService";

export default {
  name: "PackageSelectStepThree",
  data() {
    return {
      toggleTimeLapsed: null,
      selectedHorizont: null,
      iterationIndex: -1,
      price: 0,
      priceToPay: 0,
    }
  },
  mounted() {
    this.price = this.$store.getters['offer/getTotalPrice'];
    this.priceToPay = this.$store.getters['offer/getTotalPayPrice'];
    this.toggleTimeLapsed = this.$store.getters['offer/getTimeLapseFunction'];
    this.selectedHorizont = this.$store.getters['offer/getHorizont'];
    if(this.selectedHorizont !== null) {
      const isIndex = (element) => element.idStoritevVrstaIzbira === this.selectedHorizont.idStoritevVrstaIzbira;
      this.iterationIndex = this.horizont.findIndex(isIndex);
    }
  },
  computed: {
    ...mapGetters({
      selectedPackage: 'offer/getSelectedPackage',
      selectedAdditionalPackages: 'offer/getSelectedAdditionalPackages',
      timeLapse: 'timelapseFunction/getTimeLapseFunction',
      horizont: 'horizont/getHorizont',
    })
  },
  methods: {
    selectedHorizontChange(change) {
      if(change === 0) {
        this.iterationIndex--;
        if(this.iterationIndex === -1) {
          this.selectedHorizont = null;
          this.calculateTotalPriceOnHorizontChange();
          return;
        }
        this.selectedHorizont = this.horizont[this.iterationIndex];
        this.calculateTotalPriceOnHorizontChange();
        return;
      }

      this.iterationIndex++;
      this.selectedHorizont = this.horizont[this.iterationIndex];
      this.calculateTotalPriceOnHorizontChange();
    },
    calculateTotalPrice() {
      let selectedAdditionalPackagesIDs = [];
      this.selectedAdditionalPackages.forEach(element => {
        selectedAdditionalPackagesIDs.push(element.id);
      });
      selectedAdditionalPackagesIDs.push(this.selectedPackage.idStoritevVrstaIzbira);

      if(this.toggleTimeLapsed !== null) {
        selectedAdditionalPackagesIDs.push(this.toggleTimeLapsed.id);
      }

      if(this.selectedHorizont !== null) {
        selectedAdditionalPackagesIDs.push(this.selectedHorizont.idStoritevVrstaIzbira);
      }

      priceService.calculateTotalPrice(selectedAdditionalPackagesIDs).then((res) => {
        this.price = res.cena;
        this.priceToPay = res.cenaZaPlacilo
      }).catch((error) => {
        throw new Error(error)
      });
    },
    calculateTotalPriceOnHorizontChange() {
      let selectedAdditionalPackagesIDs = [];
      this.selectedAdditionalPackages.forEach(element => {
        selectedAdditionalPackagesIDs.push(element.id);
      });
      selectedAdditionalPackagesIDs.push(this.selectedPackage.idStoritevVrstaIzbira);

      if(this.selectedHorizont !== null) {
        selectedAdditionalPackagesIDs.push(this.selectedHorizont.idStoritevVrstaIzbira);
      }

      if(this.toggleTimeLapsed !== null) {
        selectedAdditionalPackagesIDs.push(this.toggleTimeLapsed.id);
      }

      priceService.calculateTotalPrice(selectedAdditionalPackagesIDs).then((res) => {
        this.price = res.cena;
        this.priceToPay = res.cenaZaPlacilo;
      }).catch((error) => {
        throw new Error(error)
      });
    },
    next() {
      this.$store.dispatch('offer/setTimeLapseFunction', this.toggleTimeLapsed);
      this.$store.dispatch('offer/setHorizont', this.selectedHorizont);
      this.$store.dispatch('offer/setTotalPrice', this.price);
      this.$store.dispatch('offer/setTotalPayPrice', this.priceToPay);
      this.$router.push({name: 'Package_select_step_4'});
    }
  }
}
</script>

<style scoped>

</style>
