<template>
  <v-container>
    <BreadcrumbsApp/>
    <v-row class="layout-2col wrapper">
      <v-col class="descCol">
        <hr>
        <p>T-2 TV lahko gledate na TV sprejemniku z aplikacijo T-2 TV, spremljate jo lahko tudi preko vašega osebnega računalnika in mobilnih naprav z aplikacijo tv2go.</p>
        <p>Aplikacija T-2 TV deluje na pametnih televizorjih in napravah z OS Android TV, Samsung Tizen, Amazon Fire TV, Apple TV, LG webOS in VIDAA Smart OS.</p>
        <p>Za namestitev sledite kratkim navodilom.</p>
      </v-col>
      <v-col class="selectCol">
        <hr>
        <h3>KJE ŽELITE GLEDATI TV?</h3>
        <v-expansion-panels
          accordion
          class="watchAcc"
          v-model="openPanel"
        >
          <!--first-->
          <v-expansion-panel id="tv">
            <v-expansion-panel-header>
              <div class="blackBackground">
                <v-img
                  src="@/images/app/onTV.svg"
                ></v-img>
              </div>
              <span class="programName">NA TV SPREJEMNIKU</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="flex content">
                  <div class="userData">
                    <v-btn
                        color="primary"
                        depressed
                        class="orangeButtonL addTv"
                        to="./my-receivers"
                    >
                      DODAJTE NOV<span>TV SPREJEMNIK</span>
                    </v-btn>
                  </div>
                    <WatchTvInstructions />
                </div>
              </v-expansion-panel-content>
          </v-expansion-panel>
          <!--second-->
          <v-expansion-panel id="computer">
            <v-expansion-panel-header>
              <div class="blackBackground onLaptop">
                <v-img
                    src="@/images/app/onLaptop.svg"
                ></v-img>
              </div>
              <span class="programName">NA RAČUNALNIKU</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="flex content">
                <div class="userData">
                <WatchTvUserData />
                </div>
                <WatchLaptopInstructions />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--third-->
          <v-expansion-panel id="mobile">
            <v-expansion-panel-header>
              <div class="blackBackground onMobile">
                <v-img
                  src="@/images/app/onMobile.svg"
                ></v-img>
              </div>
              <span class="programName">NA MOBILNI NAPRAVI</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="flex content">
                <div class="userData">
                <WatchTvUserData />
                  </div>
                <WatchMobileInstructions />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WatchTvUserData from "@/components/WatchTvUserData";
import WatchTvInstructions from "@/components/WatchTvInstructions";
import BreadcrumbsApp from "@/components/BreadcrumbsApp";
import WatchLaptopInstructions from "@/components/WatchLaptopInstructions";
import WatchMobileInstructions from "@/components/WatchMobileInstructions";

export default {
  name: "WatchTv",
  components: {WatchMobileInstructions, WatchLaptopInstructions, BreadcrumbsApp, WatchTvInstructions, WatchTvUserData},
  data() {
    return {
      openPanel: null,
      subscription: null
    }
  },
  watch: {
    openPanel: function (value) {
      let scrollTo = null;
      if (value === 0) {
        scrollTo = 'tv';
      } else if (value === 1) {
        scrollTo = 'computer';
      } else if (value === 2) {
        scrollTo = 'mobile';
      }
      if (!scrollTo) return;
      setTimeout(() => {
        this.$vuetify.goTo(`#${scrollTo}`, {duration: 500, offset: 30,});
      }, 600);
    }
  },
}
</script>

<style scoped>

</style>
