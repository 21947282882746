<template>
  <v-container>
    <div>
      <BreadcrumbsApp/>
      <v-row class="layout-2col wrapper">
        <v-col class="descCol">
          <hr>
          <p>V mojem profilu lahko hitro in enostavno spremenite svoje osebne podatke.</p>

        </v-col>
        <v-col class="selectCol">
          <v-tabs
            v-model="tab"
            class="profile-tabs"
          >
            <v-tab>
              Moj podatki
            </v-tab>
            <v-tab>
              Moje kartice
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text class="relative">
                  <div class="personal-profile">
                    <h3>Osebni podatki</h3>
                    <PersonalDataProfile :user="JSON.parse(JSON.stringify(user))" :address="address"/>
                    <ul>
                      <li>
                        <span class="lightBlue">Ime in priimek</span>
                        <span>{{ user.name }}</span>
                      </li>
                      <li>
                        <span class="lightBlue">Telefonska številka</span>
                        <span>{{ user.phone }}</span>
                      </li>
                      <li>
                        <span class="lightBlue">Ulica in hišna številka</span>
                        <span>{{ address.street }} {{ address.houseNumber }}</span>
                      </li>
                      <li>
                        <span class="lightBlue">Poštna številka in kraj</span>
                        <span>{{ address.postCode }}</span>
                      </li>
                      <li>
                        <span class="lightBlue">Davčna številka</span>
                        <span>{{ user.taxNumber !== 'undefined' ? user.taxNumber : null }}</span>
                      </li>
                    </ul>
                  </div>
                  <hr/>
                  <div class="email-profile">
                    <h3>Elektronski naslov</h3>
                    <ul>
                      <li>
                        <span class="lightBlue">Elektronski naslov</span>
                        <span>{{ user.email }}</span>
                      </li>
                    </ul>
                  </div>
                  <hr/>
                  <div class="password-profile">
                    <h3>Geslo</h3>
                    <v-btn
                      class="orangeButtonL outline"
                      color="primary"
                      @click="changePassword"
                    >
                      SPREMENITE GESLO
                    </v-btn>
                    <!--                    <PasswordProfile/>-->
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <div class="credit-card-box" v-for="card in cards" :key="card.id">
                    <div class="top-data">
                      <span class="title-card">{{ card.name }}</span>
                      <div class="buttons">
                        <!--                        <v-switch-->
                        <!--                          v-model="onOff"-->
                        <!--                          inset-->
                        <!--                        ></v-switch>-->
                        <CreditCardProfile/>
                      </div>
                    </div>
                    <div class="content">
                      <span class="number">{{ card.iban }}</span>
                      <v-img
                        width="52"
                        class="card-image"
                        src="@/images/app/visa.svg"
                      ></v-img>
                    </div>
                  </div>


                  <!--                  <div class="credit-card-box">-->
                  <!--                    <div class="top-data">-->
                  <!--                      <span class="title-card">Službena Visa</span>-->
                  <!--                      <div class="buttons">-->
                  <!--                        <v-switch-->
                  <!--                          v-model="onOff"-->
                  <!--                          inset-->
                  <!--                        ></v-switch>-->
                  <!--                        <CreditCardProfile/>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                    <div class="content">-->
                  <!--                      <span class="number"><sup>****&nbsp;&nbsp;****&nbsp;&nbsp;****</sup>&nbsp;&nbsp;1234</span>-->
                  <!--                      <span class="date">12/2024</span>-->
                  <!--                      <v-img-->
                  <!--                        width="52"-->
                  <!--                        class="card-image"-->
                  <!--                        src="@/images/app/visa.svg"-->
                  <!--                      ></v-img>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!--                  <div class="credit-card-box deactivated">-->
                  <!--                    <div class="top-data">-->
                  <!--                      <span class="title-card">Druga Visa</span>-->
                  <!--                      <div class="buttons">-->
                  <!--                        <v-switch-->
                  <!--                          v-model="onOff2"-->
                  <!--                          inset-->
                  <!--                        ></v-switch>-->
                  <!--                        <CreditCardProfile/>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                    <div class="content">-->
                  <!--                      <span class="number"><sup>****&nbsp;&nbsp;****&nbsp;&nbsp;****</sup>&nbsp;&nbsp;1234</span>-->
                  <!--                      <span class="date">12/2024</span>-->
                  <!--                      <v-img-->
                  <!--                        width="52"-->
                  <!--                        class="card-image"-->
                  <!--                        src="@/images/app/visa.svg"-->
                  <!--                      ></v-img>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </v-card-text>

                <!--                <v-btn-->
                <!--                  width="100%"-->
                <!--                  max-width="330px"-->
                <!--                  class="orangeButtonL"-->
                <!--                  color="primary"-->
                <!--                >-->
                <!--                  <strong>Zamenjaj kartico</strong>-->
                <!--                </v-btn>-->
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbsApp from "@/components/BreadcrumbsApp";
import PersonalDataProfile from "@/components/PersonalDataProfile";
// import PasswordProfile from "@/components/PasswordProfile";
import CreditCardProfile from "@/components/CreditCardProfile";
import userService from "../../services/userService";

export default {
  name: "Profile",
  components: {
    CreditCardProfile,
    // PasswordProfile,
    PersonalDataProfile,
    BreadcrumbsApp
  },
  data() {
    return {
      tab: null,
      onOff: true,
      onOff2: false,
      cards: []
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    address() {
      return this.$store.getters['user/address'];
    }
  },
  mounted() {
    this.getUserCards();
  },
  methods: {
    getUserCards() {
      userService.cards()
        .then(res => {
          if (typeof res.data === 'object') {
            this.cards = [
              {
                id: res.data.id,
                iban: res.data.maskedPan,
                name: 'Kartica ' + res.data.id,
              }
            ];
            return;
          }

          this.cards = res.data.map(item => {
            return {
              id: item.id,
              iban: item.maskedPan,
              name: 'Kartica ' + item.id,
            }
          });
        });
    },
    changePassword() {
      window.location.replace(window.OAUTH_URL + 'reset_password');
    }
  }
}
</script>

<style scoped>

</style>
