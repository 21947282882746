<template>

  <v-dialog
    v-model="dialog"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="editButton"
        color="primary"
        plain
        v-bind="attrs"
        v-on="on"
        small
      >
        <span class="warpit_icon_edit"></span>
      </v-btn>
    </template>
    <v-card class="popUp form">
      <div class="popUpHeader">
        <h3 class="margin20">UREJANJE OSEBNIH PODATKOV</h3>
        <a
          class="closeButton"

          @click="dialog = false"

        >
          <span class="warpit_icon_x"></span>
        </a>
      </div>
      <hr class="gradient">
      <v-card-text>
        <v-container>
          <v-col class="selectCol">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form"
            >
              <v-text-field
                label="Ime in priimek"
                :rules="nameRules"
                required
                v-model="user.name">
              </v-text-field>
              <v-text-field
                label="Telefonska številka"
                @focusout="checkPhoneNumber"
                :rules="[phoneNumberRules.required, phoneNumberIsValid]"
                required
                v-model="user.phone">
              </v-text-field>
              <!--            <v-col cols="12">-->
              <!--              <v-text-field label="Priimek" required></v-text-field>-->
              <!--            </v-col>-->
              <v-text-field
                label="Ulica"
                :rules="streetRules"
                required
                v-model="address.street">
              </v-text-field>
              <v-text-field
                label="Hišna številka"
                :rules="houseNumberRules"
                required
                v-model="address.houseNumber">
              </v-text-field>
              <v-text-field
                label="Poštna številka"
                required
                :rules="postNumberRules"
                v-model="address.postCode">
              </v-text-field>
              <v-text-field
                label="Kraj"
                :rules="cityRules"
                required
                v-model="address.city">
              </v-text-field>
              <!--            <v-col cols="9">-->
              <!--              <v-text-field label="Kraj" required></v-text-field>-->
              <!--            </v-col>-->
              <hr class="black"/>
              <div class="flex company">
                <v-switch v-model="isCompany" inset @change="resetVatNumber()"></v-switch>
                <label>Naročnik je pravna oseba</label>
              </div>
              <v-text-field
                v-if="isCompany"
                v-model="vat"
                :rules="vatRules"
                :counter="3"
                label="Davčna številka"
                required
                class="vatField">
              </v-text-field>
            </v-form>
          </v-col>
        </v-container>
      </v-card-text>
      <hr class="gradient margin">
      <v-card-actions class="buttons">
        <v-btn
          class="primary saveButton"
          text
          @click="save()"
        >
          Shranite
        </v-btn>
        <a
          class="cancelButton underline-hover"
          @click="dialog = false"
        >
          Prekličite
        </a>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from '../services/userService';
import phoneNumberService from "@/services/phoneNumberService";

export default {
  name: "PersonalDataProfile",
  props: {
    user: {
      type: Object,
      default: null
    },
    address: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      phoneNumberIsValid: false,
      nameRules: [
        v => !!v || 'Prosimo vpišite ime.',
        v => (v && v.length >= 1) || 'Vpišite vsaj 1 znak'
      ],
      phoneNumberRules: {
        required: v => !!v || "Prosimo vnesite pravilno telefonsko številko. Primer: 040123123",
      },
      lastNameRules: [
        v => !!v || 'Prosimo vpišite priimek.',
        v => (v && v.length >= 3) || 'Vpišite vsaj 3 znake'
      ],
      streetRules: [
        v => !!v || 'Prosimo vpišite naslov.',
        v => (v && v.length >= 1) || 'Vpišite vsaj 1 znak'
      ],
      houseNumberRules: [
        v => !!v || 'Prosimo vpišite hišno številko.',
        v => (v && v.length >= 1) || 'Vpišite vsaj 1 znak'
      ],
      cityRules: [
        v => !!v || 'Prosimo vpišite kraj.',
        v => (v && v.length >= 3) || 'Vpišite vsaj 3 znake'
      ],
      postNumberRules: [
        v => !!v || 'Prosimo vpišite poštno številko.',
        v => (v && v.length >= 3) || 'Vpišite vsaj 3 znake'
      ],
      vatRules: [
        v => !!v || 'Prosimo vpišite davčno številko.',
        v => (v && v.length <= 10) || 'Vpišite vsaj 3 znake'
      ],
      valid: true,
      vat: null,
      dialog: false,
      isCompany: false,
    }
  },
  mounted() {
    this.vat = this.user.taxNumber != 'undefined' ? this.user.taxNumber : null;
    this.checkPhoneNumber();
  },
  methods: {
    resetVatNumber() {
      this.vat = "";
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const formData = new FormData();
      formData.append("name", this.user.name);
      formData.append("address", this.address.street + ', ' + this.address.city + ', ' + this.address.postCode);
      formData.append("hisnaStevilka", this.address.houseNumber);
      formData.append("phone", this.user.phone);
      formData.append("taxNumber", this.vat);

      userService.update(formData).then(() => {
        this.$store.dispatch("user/getUser")
          .then(() => {
            this.dialog = false;
          })
      }).catch((error) => {
        throw new Error(error)
      });
    },
    checkPhoneNumber() {
      if (this.user.phone === "" || this.user.phone === null) {
        return false;
      }
      phoneNumberService.validate(this.user.phone)
        .then(res => {
          if (!res.isValid) {
            this.phoneNumberIsValid = 'Prosimo vnesite pravilno telefonsko številko. Primer: 040123123';
            return false;
          }
          this.phoneNumberIsValid = true;
          return true;
        })
    }
  }
}
</script>

<style scoped>

</style>
