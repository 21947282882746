<template>
  <div class="instructions">
    <h3>KAKO SE LOTITI TEGA?</h3>
    <ul>
      <li>
        <span class="number">1</span>
        <p>V meniju mobilne naprave poiščite trgovino z aplikacijami in namestite aplikacijo T-2 tv2go.</p>
      </li>
      <li>
        <span class="number">2</span>
        <p>Ob prvem zagonu se prijavite z uporabniškim imenom in geslom, ki jih imate zapisane na ekranu. Podatke za prijavo ste prejeli tudi na vaš elektronski naslov.</p>
      </li>

      <li><span class="number">3</span>
        Udobno se namestite in uživajte ob ogledu! </li>
    </ul><br><br>
  </div>
</template>

<script>
export default {
  name: "WatchMobileInstructions"
}
</script>

<style scoped>

</style>