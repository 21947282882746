<template>

  <v-dialog
    v-model="shown"
    persistent
    max-width="700px"
  >
    <v-card class="popUp form warningPopUp">
      <div class="popUpHeader">
        <h3 class="margin20">Opozorilo</h3>
        <a
          class="closeButton"
          @click="onReject"
        >
          <span class="warpit_icon_x"></span>
        </a>
      </div>
      <hr class="gradient">
      <v-card-text>
        <v-container>
          <div class="flex">
            <div class="icon">
              <v-img
                src="@/images/app/confirmation.svg"
              ></v-img>
            </div>
            <div class="content">
              <h3>{{ message }}</h3>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <hr class="gradient margin">
      <v-card-actions class="buttons">
        <div>
          <v-btn class="primary saveButton" @click="onConfirm">POTRDI</v-btn>
          <v-btn class="ml-4"
                 @click="onReject">ZAPRI
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Confirm",
  data: () => ({
    message: 'Ali ste prepričani da želite izvesti akcijo?',
    resolve: null,
    reject: null,
    shown: false,
  }),
  methods: {
    onConfirm() {
      if (this.resolve) {
        this.resolve();
        this.resolve = null;
      }
      this.shown = false;
    },
    onReject() {
      if (this.reject) {
        this.reject();
        this.reject = null;
      }
      this.shown = false;
    },
    confirm(message = null) {
      this.shown = true;
      if (message !== null) {
        this.message = message;
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    }
  }
}
</script>

<style scoped>

</style>
