<template>
  <div class="appSteps">
    <v-container>
      <div class="wrapper noPadding">
        <h1 class="mainTitle">Izbor dodatnih programskih paketov</h1>
        <hr class="title" />
      </div>
      <v-row class="layout-2col wrapper">
        <v-col class="selectCol L">
          <p class="stepDescription">
            K SVOJEMU PAKETU {{ selectedPackage.naziv }} LAHKO DODATE NASLEDNJE
            PROGRAMSKE PAKETE
          </p>
          <hr class="black nomargin" />
          <div class="searchInput">
            <v-text-field v-model="searchQuery" placeholder="Vpišite ime" clearable rounded solo dense></v-text-field>
          </div>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="item in resultQuery" :key="item.id" @click="selectedExpansionPanel = item.id"
              :class="{ selected: selectedExpansionPanel == item.id }">
              <v-expansion-panel-header>
                <v-switch @click.native.stop v-model="addedAdditionalPackages" @change="calculateTotalPrice"
                  @click="addPackage(item)" :value="item" :disabled="item.disabled" inset></v-switch>
                <span class="programName">
                  {{ item.naziv }}
                  <small class="addedPrice">{{ item.cena }} € / mesec</small>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="marginL">
                <!--- PODPAKETI

                <v-expansion-panels accordion v-if="item.avtomatskoVklopljeneStoritve && item.avtomatskoVklopljeneStoritve.length > 0">
                  <v-expansion-panel
                      v-for="service in item.avtomatskoVklopljeneStoritve"
                      :key="service.id"
                      @click="selectedExpansionPanel = service.id"
                      :class="{selected: selectedExpansionPanel == service.id}"
                  >
                    <v-expansion-panel-header>
                      <v-switch
                          @click.native.stop
                          v-model="addedAdditionalPackages"
                          @change="calculateTotalPrice"
                          :value="service"
                          :disabled="service.disabled"
                          inset
                      ></v-switch>
                      <span class="programName">
                  {{ service.naziv }}
                </span>
                    </v-expansion-panel-header
                    >
                    <v-expansion-panel-content class="marginL">-->
                <!-- preveri strukturo gnezdenih paketov
                <p>{{ service.opis }}</p>
                <h5 class="sumTitle">Programi</h5>
                <hr class="black"/>
                <ul class="feature-list">
                  <li
                      v-for="(channel, index) in service.additionalPackageChannels.channels"
                      :key="index"
                  >
                    {{ channel }}
                  </li>
                </ul>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
           konec dodatnih paketov -->
                <!--
                                <div v-else> -->

                <p>{{ item.opis }}</p>
                <div v-if="item.additionalPackageChannels.channels &&
                  item.additionalPackageChannels.channels.length > 0
                  ">
                  <h5 class="sumTitle">Programi</h5>
                  <hr class="black" />
                  <ul class="feature-list">
                    <li v-for="(channel, index) in item.additionalPackageChannels
                      .channels" :key="index">
                      {{ channel }}
                    </li>
                  </ul>
                </div>
                <!-- </div> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="navButtons">
            <router-link :to="{ name: 'Package_select_step_1' }" class="underline-hover">Prejšnji korak
            </router-link>
            <v-btn color="primary" depressed class="orangeButtonL" @click="next">
              Naslednji korak
            </v-btn>
          </div>
        </v-col>
        <v-col class="descCol R">
          <div class="sum">
            <h5 class="sumTitle">POVZETEK NAKUPA</h5>
            <hr />
            <span class="sectionTitle">IZBRANI PAKET</span>
            <ul class="list">
              <li>
                <span class="package">{{ selectedPackage.naziv }}</span>
                <span class="pricePckage">
                  {{ selectedPackage.cena }}
                  <small>€/mesec</small>
                </span>
              </li>
            </ul>
            <hr />
            <span class="sectionTitle">DODATNI PROGRAMSKI PAKETI</span>
            <ul class="list borders">
              <li v-for="item in addedAdditionalPackages" :key="item.id">
                <span>
                  {{ item.naziv }}
                </span>
                <span class="pricePckage">
                  {{ item.cena }}
                  <small>€/mesec</small>
                </span>
              </li>
            </ul>
            <hr class="last" />
            <ul class="list sum">
              <li>
                <span>Za plačilo</span>
                <span class="pricePckage">
                  {{ price }}
                  <small>€/mesec</small>
                </span>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import priceService from "../../services/priceService";

export default {
  name: "PackageSelectStepTwo",
  components: {},
  computed: {
    resultQuery() {
      if (!this.searchQuery) {
        return this.additionalPackages;
      }

      return this.additionalPackages.filter((item) => {
        if (item.naziv.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return true;
        }

        if (
          item.additionalPackageChannels.channels.find((el) =>
            el.toLowerCase().includes(this.searchQuery.toLowerCase())
          ) !== undefined
        ) {
          return true;
        }

        return false;
      });
    },
    additionalPackages() {
      let blackList = [];
      const additionalPackages =
        this.$store.getters["additionalPackages/getAdditionalPackages"];

      additionalPackages.forEach((item) => {
        if (
          item.vTrznemPaketu.includes(
            this.selectedPackage.idStoritevVrstaIzbira
          )
        ) {
          blackList.push(item.id);
          item.avtomatskoVklopljeneStoritve.forEach((element) => {
            blackList.push(element.id);
          });
        }
      });

      const includedPackageWithChild = this.addedAdditionalPackages.filter(
        (el) => el.avtomatskoVklopljeneStoritve !== null
      );
      if (includedPackageWithChild.length > 0) {
        includedPackageWithChild.forEach((item) => {
          item.avtomatskoVklopljeneStoritve.forEach((element) => {
            blackList.push(element.id);
          });
        });
      }

      return additionalPackages.filter((item) => {
        return !blackList.includes(item.id);
      });
      // return additionalPackages;  zamenjano z zgornjim
    },
    ...mapGetters({
      selectedPackage: "offer/getSelectedPackage",
      selectedAdditionalPackages: "offer/getSelectedAdditionalPackages",
    }),
  },
  mounted() {
    this.price = this.$store.getters["offer/getTotalPrice"];
    this.addedAdditionalPackages =
      this.$store.getters["offer/getSelectedAdditionalPackages"];

    this.$store.dispatch("alert/toggleShow", false);
  },
  data() {
    return {
      addedAdditionalPackages: [],
      searchQuery: null,
      price: 0,
      priceToPay: 0,
      selectedExpansionPanel: -1,
    };
  },
  methods: {
    calculateTotalPrice() {
      try {
        let selectedAdditionalPackagesIDs = [];
        selectedAdditionalPackagesIDs = this.addedAdditionalPackages.map(
          (item) => item.id
        );
        selectedAdditionalPackagesIDs.push(
          this.selectedPackage.idStoritevVrstaIzbira
        );

        priceService
          .calculateTotalPrice(selectedAdditionalPackagesIDs)
          .then((res) => {
            this.price = res.cena;
            this.priceToPay = res.cenaZaPlacilo;
          })
          .catch((error) => {
            throw new Error(error);
          });
      } catch (error) {
        alert("Napaka pri izracunu cene");
      }
    },
    next() {
      if (
        this.selectedPackage.idStoritevVrstaIzbira === 5682 &&
        this.addedAdditionalPackages.length === 0
      ) {
        this.$store.dispatch("alert/setTitle", "Izbrati morate vsaj en paket!");
        this.$store.dispatch(
          "alert/setMessage",
          "Paket T-2 TV Mini je namenjen izbiri programov po vašem okusu. Prosim, izberite programske pakete, ki jih želite gledati."
        );
        this.$store.dispatch("alert/toggleShow", true);
        return;
      }

      this.$store.dispatch(
        "offer/setAdditionalPackages",
        this.addedAdditionalPackages
      );
      this.$store.dispatch("offer/setTotalPrice", this.price);
      this.$store.dispatch("offer/setTotalPayPrice", this.priceToPay);
      this.$router.push({ name: "Package_select_step_3" });
    },
    addPackage(item) {
      if (item.avtomatskoVklopljeneStoritve === null) {
        return;
      }

      item.avtomatskoVklopljeneStoritve.forEach((element) => {
        const additionalPackage = this.addedAdditionalPackages.find(
          (el) => el.id === element.id
        );
        if (additionalPackage) {
          const index = this.addedAdditionalPackages.indexOf(additionalPackage);
          if (index !== -1) {
            this.addedAdditionalPackages.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
