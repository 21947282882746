<template>
  <div class="appSteps">
    <v-container>
      <v-row class="layout-2col wrapper">
        <v-col class="descCol">
          <h1>Prosimo, zaključite registracijo.</h1>
          <hr/>
          <p>
            Izberite svoj paket in mu dodajte dodatne programe, ki jih ta paket
            ne vključuje, a bi jih želeli gledati.
          </p>
        </v-col>
        <v-col class="selectCol">
          <v-form ref="form" v-model="valid" lazy-validation class="form">
            <v-text-field v-model="user.name" :rules="nameRules" label="Ime in priimek" required></v-text-field>

            <v-text-field v-model="user.phone" @focusout="checkPhoneNumber"
                          :rules="[phoneNumberRules.required, phoneNumberIsValid]"
                          label="Telefonska številka" required>
            </v-text-field>

            <v-text-field v-model="address.street" :rules="streetRules" label="Naslov" required></v-text-field>

            <v-text-field v-model="address.houseNumber" :rules="houseNumberRules" label="Hišna številka" required>
            </v-text-field>

            <v-text-field v-model="address.postCode" :rules="postNumberRules" label="Poštna številka" required
                          class="postNumber"></v-text-field>

            <v-text-field v-model="address.city" :rules="cityRules" label="Kraj" required></v-text-field>
            <hr class="black"/>

            <div class="flex company">
              <v-switch v-model="isCompany" @change="resetVatNumber()" inset></v-switch>
              <label>Naročnik je pravna oseba</label>
            </div>
            <v-text-field v-if="isCompany" v-model="user.taxNumber" :counter="8" :rules="vatRules"
                          label="Davčna številka" required class="vatField"></v-text-field>

            <hr class="black"/>
            <div class="flex terms">
              <v-switch v-model="acceptTerms" :rules="[
                (v) =>
                  !!v ||
                  'Za nadaljevanje se morate strinjati s splošnimi pogoji.',
              ]" required inset></v-switch>
              <label>Prosimo, za nadaljevanje potrdite, da se strinjate s
                <router-link to="/terms" class="underline-hover">splošnimi pogoji.
                </router-link>
              </label>
            </div>
            <div class="flex terms resMargin">
              <v-switch v-model="collectingData" required inset></v-switch>
              <label>Dovoljujem osebno trženje</label>
            </div>
            <hr class="gradient"/>
          </v-form>

          <div class="navButtons">
            <router-link :to="{ name: 'PromoStep_2' }" class="underline-hover">Prejšnji korak</router-link>
            <v-btn color="primary" depressed class="orangeButtonL" @click="next">
              POTRDI
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import userService from "../../services/userService";
import promoCodeService from "../../services/promoCodeService";
import phoneNumberService from "@/services/phoneNumberService";

export default {
  name: "PackageSelectStepFour",
  data: () => ({
    isCompany: false,
    acceptTerms: false,
    collectingData: false,
    valid: true,
    phoneNumberIsValid: false,
    nameRules: [
      (v) => !!v || "Prosimo vpišite ime.",
      (v) => (v && v.length >= 1) || "Vpišite vsaj 1 znak",
    ],
    phoneNumberRules: {
      required: v => !!v || "Prosimo vnesite pravilno telefonsko številko. Primer: 040123123",
    },
    lastNameRules: [
      (v) => !!v || "Prosimo vpišite priimek.",
      (v) => (v && v.length >= 3) || "Vpišite vsaj 3 znake",
    ],
    streetRules: [
      (v) => !!v || "Prosimo vpišite naslov.",
      (v) => (v && v.length >= 1) || "Vpišite vsaj 1 znak",
    ],
    houseNumberRules: [
      (v) => !!v || "Prosimo vpišite hišno številko.",
      (v) => (v && v.length >= 1) || "Vpišite vsaj 1 znak",
    ],
    cityRules: [
      (v) => !!v || "Prosimo vpišite kraj.",
      (v) => (v && v.length >= 3) || "Vpišite vsaj 3 znake",
    ],
    postNumberRules: [
      (v) => !!v || "Prosimo vpišite poštno številko.",
      (v) => (v && v.length >= 3) || "Vpišite vsaj 3 znake",
    ],
    vatRules: [
      v => !!v || 'Prosimo vpišite davčno številko.',
      v => !!Number.isInteger(Number(v)) || 'Prosimo vpišite številko.',
      v => (v && v.length === 8) || 'Vpišite 8 znakov',
    ],
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    address() {
      return this.$store.getters["user/address"];
    },
    code() {
      return this.$store.getters["promoCode/getCode"];
    },
    token() {
      return this.$store.getters["auth/getAccessToken"];
    },
    isValid() {
      return this.$store.getters["promoCode/getValid"];
    },
  },
  mounted() {
    if (!this.isValid) {
      return this.$router.push({name: "PromoStep_1"})
    }

    if (this.user.taxNumber !== null && this.user.taxNumber !== undefined) {
      this.isCompany = true;
    }

    this.checkPhoneNumber();
  },
  methods: {
    resetVatNumber() {
      this.user.taxNumber = "";
    },

    next() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const formData = new FormData();
      formData.append("name", this.user.name);
      formData.append(
        "address",
        this.address.street +
        ", " +
        this.address.city +
        ", " +
        this.address.postCode
      );
      formData.append("hisnaStevilka", this.address.houseNumber);
      formData.append("phone", this.user.phone);
      formData.append("taxNumber", this.user.taxNumber);

      userService
        .update(formData)
        .then(() => {
          this.$store.dispatch("user/getUser").then(() => {
            promoCodeService
              .createSubscriptionPromo(this.code, this.token)
              .then(() => {
                if (this.$store.getters["user/subscriptions"].length !== 0) {
                  return;
                }
                this.$router.push({name: "App_home"});
                return;
              });
          });
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    checkPhoneNumber() {
      if (this.user.phone === "" || this.user.phone === null) {
        return false;
      }
      phoneNumberService.validate(this.user.phone)
        .then(res => {
          if (!res.isValid) {
            this.phoneNumberIsValid = 'Prosimo vnesite pravilno telefonsko številko. Primer: 040123123';
            return false;
          }
          this.phoneNumberIsValid = true;
          return true;
        })
    }
  },
};
</script>

<style scoped>

</style>
