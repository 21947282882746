<template>
  <div>
    <!-- section 1 -->
    <div class="landingBackground">
      <div class="section-one">
        <div class="wrapper">
          <h1>Niste naročnik T-2?</h1>
          <h2>Tudi vi lahko spremljate najboljše TV programe kot je </h2>
          <span class="changing-text" style="display: inline-block;  margin: 0 auto; ">{{ this.changingText }}</span>
          <hr/>
          <p class="shorDescription">Preizkusite našo pretočno televizijo in glejte vrhunske športne prenose, najboljše
            filmske uspešnice in ekskluzivne oddaje v zakulisju dogajanja v kateremkoli internetnem omrežju v Sloveniji
            in
            EU. </p>
          <v-btn
            v-if="!isAuthenticated"
            color="primary"
            depressed
            class="orangeButtonL"
            @click="login"
          >
            PREIZKUSITE ZDAJ
          </v-btn>
          <v-btn
            v-else
            color="primary"
            depressed
            class="orangeButtonL"
            @click="toCard"
          >
            {{ buttonText }}
          </v-btn>
          <span class="free">{{ freeTrialText }}</span>
          <span class="freeTrialSubtext">{{ freeTrialTextSubtext }}</span>
        </div>
      </div>
    </div>
    <div class="sep">&nbsp;</div>
    <!-- section 2 -->
    <div class="section-two">
      <div class="wrapper text-center">
        <h2>Najboljši športni in filmski programi po prijazni ceni.
          <span>Tudi, če niste naročnik T-2.</span></h2>
        <hr/>
        <p class="shorDescription">Pretočna T-2 TV v vaš dom pripelje bogat izbor filmskih programov, izjemne nogometne
          in košarkarske športne lige ter ostale zanimive športne dogodke. Vse vsebine lahko spremljate tudi na poti z
          mobilno aplikacijo – kjerkoli v EU. Potrebujete le internetno povezavo. </p>
      </div>
      <div class="relative">
        <div class="computerContainer">
          <img class="computerImage" src="../../images/best/comp.png" alt="">
        </div>
        <div id="infinite" class="landing-slider">
          <ul class="landing-wrapper">
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-1.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-2.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-3.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-4.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-1.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-2.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-3.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-4.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-1.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-2.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-3.png"></v-img>
            </li>
            <li class="landing-image">
              <v-img src="@/images/best/Rectangle-4.png"></v-img>
            </li>
          </ul>
        </div>
      </div>
      <div class="wrapper">

        <v-btn
          v-if="!isAuthenticated"
          color="primary"
          depressed
          class="orangeButtonL"
          @click="login"
        >
          PREIZKUSITE ZDAJ
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          class="orangeButtonL"
          @click="toCard"
        >
          {{ buttonText }}
        </v-btn>
        <span class="free">{{ freeTrialText }}</span>
        <span class="freeTrialSubtext">{{ freeTrialTextSubtext }}</span>
      </div>
    </div>
    <div class="sep">&nbsp;</div>
    <!-- section 3-->
    <div class="section-three">
      <div class="wrapper">
        <h2>Izberite svoj paket...</h2>
        <div class="chooseYourPackage flex">
          <v-card class="boxBlack" v-for="(view, index) in packages" v-bind:key="index">
            <PackageTabItem :selectedPackage="view" :allPrograms="packagesAllPrograms[index]" :is-landing-page="true"/>
          </v-card>
        </div>
        <h2>...in mu po želji dodajte še dodatne programske pakete s pestrim naborom TV programov.</h2>
      </div>
      <swiper
        :options="swiperOptions"
        class="programs"
      >
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/sport_tv_1a.png"
            ></v-img>
            <label>Šport TV 1</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/sport_tv_2a.png"
            ></v-img>
            <label>Šport TV 2</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/sport_tv_3a.png"
            ></v-img>
            <label>Šport TV 3</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/as_1a.png"
            ></v-img>
            <label>Arena Sport 1</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/as_2a.png"
            ></v-img>
            <label>Arena Sport 2</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/as_3a.png"
            ></v-img>
            <label>Arena Sport 3</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/as_4a.png"
            ></v-img>
            <label>Arena Sport 4</label>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="box">
            <v-img
              src="@/images/programs/arena_e-sport.png"
            ></v-img>
            <label>Arena Sport 5</label>
          </div>
        </swiper-slide>

      </swiper>
      <div class="wrapper">
        <div class="mostPopular">

          <div class="flex">
            <ul>
              <li class="title"><h4>Najboljši športni programi</h4></li>
              <li>Arena Sport 1</li>
              <li>Arena Esport</li>
              <li>Arena Fight HD</li>
              <li>Šport TV</li>
              <li>Eurosport</li>
            </ul>

            <ul>
              <li class="title"><h4>Najboljši filmski programi</h4></li>
              <li>CineStar Premiere 1 HD</li>
              <li>CineStar Premiere 2 HD</li>
              <li>Filmbox Stars HD</li>
              <li>Filmbox Extra HD</li>
              <li>Filmbox Premium HD</li>
            </ul>

            <ul>
              <li class="title"><h4>Najboljši tujejezični programi</h4></li>
              <li>Duna World HD</li>
              <li>Duna HD</li>
              <li>Dom Kino</li>
              <li>ChannelOneRussia</li>
              <li>M1 HD</li>
            </ul>
          </div>
          <v-dialog
            v-model="dialog"
            width="500"
            class="landingPopUp"
          >
            <template v-slot:activator="{ on, attrs }">
              <a class="showAllPrograms"
                 v-bind="attrs"
                 v-on="on"
              >
                Prikaži vse
              </a>
            </template>

            <v-card class="popUp">
              <div class="popUpHeader">
                <h3>Programski paketi</h3>
                <a
                  class="closeButton"

                  @click="dialog = false"
                ><span class="warpit_icon_x"></span>
                </a>

              </div>
              <hr class="gradient">

              <div class="contentLanding">
                <ProgramsListBrowser/>
              </div>

            </v-card>
          </v-dialog>
          <hr/>
          <v-btn
            v-if="!isAuthenticated"
            color="primary"
            depressed
            class="orangeButtonL"
            @click="login"
          >
            PREIZKUSITE ZDAJ
          </v-btn>
          <v-btn
            v-else
            color="primary"
            depressed
            class="orangeButtonL"
            @click="toCard"
          >
            {{ buttonText }}
          </v-btn>
          <span class="free">{{ freeTrialText }}</span>
          <span class="freeTrialSubtext">{{ freeTrialTextSubtext }}</span>
        </div>
      </div>
    </div>
    <div class="sep">&nbsp;</div>
    <!-- section 4-->
    <div class="section-four">
      <div class="wrapper">
        <h2>Vrhunska TV izkušnja.<span>V vseh paketih.</span></h2>
        <div class="chooseYourPackage flex">
          <v-card class="boxBlack">
            <div class="content">
              <h3>Vedno nove vsebine v T-2 videoteki.</h3>
              <span class="desc">Z brezplačnim ogledom top filma meseca.</span>
              <v-img
                alt="Vedno nove vsebine v T-2 videoteki."
                src="@/images/image.png"
              ></v-img>
            </div>
          </v-card>
          <v-card class="boxBlack">
            <div class="content">
              <h3>Tvoje vsebine pod tvojim profilom.</h3>
              <span class="desc">T-2 televizija ti glede na tvoj okus priporoča vsebine, ki te zanimajo.</span>
              <v-img
                alt="Tvoje vsebine pod tvojim profilom."
                src="@/images/image1.png"
              ></v-img>
            </div>
          </v-card>
          <v-card class="boxBlack">
            <div class="content">
              <h3>Do 7 dni časovnega zamika.</h3>
              <span
                class="desc">Edinstveno prikazovanje slik med prevrtavanjem z možnostjo ogleda do 7 dni nazaj.</span>
              <div class="relativeVideo">
                <v-img
                  alt="Do 7 dni časovnega zamika."
                  src="@/images/image2.png"
                ></v-img>
                <video class="video" playsinline autoplay muted loop
                       poster='@/images/tv-tv2-casovni-zamik_compressed.mp4'>
                  <source :src='require("@/images/tv-tv2-casovni-zamik_compressed.mp4")' type='video/mp4'>
                </video>
              </div>
            </div>
          </v-card>
          <v-card class="boxBlack">
            <div class="content">
              <h3>Maratonsko gledanje priljubljene serije</h3>
              <span
                class="desc">Po končani epizodi se naslednji del predvaja samodejno.</span>
              <v-img
                alt="Do 7 dni časovnega zamika."
                src="@/images/image3.png"
              ></v-img>

            </div>
          </v-card>

        </div>
        <v-btn
          v-if="!isAuthenticated"
          color="primary"
          depressed
          class="orangeButtonL"
          @click="login"
        >
          PREIZKUSITE ZDAJ
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          class="orangeButtonL"
          @click="toCard"
        >
          {{ buttonText }}
        </v-btn>
        <span class="free">{{ freeTrialText }}</span>
        <span class="freeTrialSubtext">{{ freeTrialTextSubtext }}</span>
      </div>
    </div>
    <div class="sep">&nbsp;</div>
    <!-- section 5-->
    <div class="section-five">
      <div class="wrapper">
        <h2>Vrhunska TV izkušnja.<span>Na vseh napravah.</span></h2>
        <hr/>
        <p class="shorDescription">T-2 televizija je mnogo več kot le izvrstna ponudba TV programov. Ponuja vam
          popolnoma novo gledalsko izkušnjo in vas vabi v svet, ki upošteva vaše gledalske navade in se jim prilagaja.
          Na vašem pametnem televizorju, računalniku ali mobilni napravi.
        </p>

        <div class="relativeVideo">
          <video class="video" playsinline autoplay muted loop
                 poster='@/images/tv-t2_glej-na-vseh-napravah_screen_3_compressed-2.mp4'>
            <source :src='require("@/images/tv-t2_glej-na-vseh-napravah_screen_3_compressed-2.mp4")' type='video/mp4'>
          </video>
          <v-img
            src="@/images/serije.png"
          ></v-img>
        </div>
        <v-btn
          v-if="!isAuthenticated"
          color="primary"
          depressed
          class="orangeButtonL"
          @click="login"
        >
          PREIZKUSITE ZDAJ
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          class="orangeButtonL"
          @click="toCard"
        >
          {{ buttonText }}
        </v-btn>
        <span class="free">{{ freeTrialText }}</span>
        <span class="freeTrialSubtext">{{ freeTrialTextSubtext }}</span>
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import {directive, Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import PackageTabItem from "@/components/PackageTabItem";

import packagesAllPrograms from "@/config/packages";
import ProgramsListBrowser from "@/components/ProgramsListBrowser";

export default {
  components: {
    ProgramsListBrowser,
    PackageTabItem,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      dialog: false,
      words: ["CineStar Premiere", "Arena Sport", "Eurosport", "Šport TV"],
      freeTrialText: "MESEC DNI BREZPLAČNO",
      freeTrialTextSubtext: "Paket maxi + Časovni zamik",
      changingText: '',
      packagesAllPrograms,
      packages: [],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 50,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40
          }
        }
      }
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    buttonText() {
      return this.$store.getters['user/subscriptions'].length > 0 ? 'VSTOPI V PORTAL' : 'DOKONČAJTE NAKUP';
    }
  },
  mounted() {
    this.getPackages();
    this.AnimatedText();
  },
  methods: {
    login() {
      this.$auth.login();
    },
    toCard() {
      this.$router.push({name: 'App_home'});
    },
    getPackages() {
      let data = [
        {naziv: "T-2 TV Mini", cena: 5.99},
        {naziv: "T-2 TV Midi", cena: 10.99},
        {naziv: "T-2 TV Maxi", cena: 20.99},
        //{ naziv: "Po meri", cena: 19.99}
      ]

      data.forEach(element => this.packages.push(element))
    },
    AnimatedText() {
      let currentText = parseInt(Math.random() * this.words.length);
      let areaText = this.words[0];
      this.t1 = setInterval(function () {
        let c = parseInt(Math.random() * Math.max(this.words[currentText].length, areaText.length));
        let s = this.words[currentText][c];
        if (typeof s == 'undefined') s = " ";
        while (areaText.length < c) areaText += " ";
        areaText = (areaText.slice(0, c) + s + areaText.slice(c + 1)).trim();
        this.changingText = areaText.length === 0 ? '' : areaText;
      }.bind(this), 10);
      this.t2 = setInterval(function () {
        currentText = parseInt(Math.random() * this.words.length);
      }.bind(this), 1000);
    }
  }
}

</script>
