<template>
  <v-container>
    <v-row class="layout-2col wrapper links-underline">
      <v-col class="descCol">
        <h3>Piškotki</h3>

      </v-col>
      <v-col class="selectCol">
        <div>
          <p>Naša spletna stran za namen izboljšave delovanja uporablja piškotke.</p>
          <p>Piškotki so majhne datoteke, ki se naložijo na vaš računalnik z namenom izboljšanja uporabniške izkušnje.</p>
          <p>Spodnje tabele prikazujejo piškotke, ki jih uporabljamo na spletni strani:</p>
          <hr>
          <h3>1. Sistemski piškotki</h3>
          <p>So ključni in brez njih stran ne bo delovala, kot bi morala. Nastavijo se, ko oddate obrazec, se prijavite ali opravite kakršnokoli interakcijo s spletno stranjo, ki gre preko klikanja na enostavne povezave. Za te piškotke vaša privolitev ni potrebna.</p>
          <v-simple-table class="simpleTable responsiveTable">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" style="width:250px">
                  Domena ki namešča piškotek
                </th>
                <th>Ime piškotka</th>
                <th>Opis piškotka
                </th>
              </tr>

              </thead>
              <tbody>
              <tr>
                <td>.t-2.net
                <td>t-2-tako-kot-mora-biti_cookiecontrol</td>
                <td>Nujni piškotek, ki si zapomni vašo izbiro nastavitev za nalaganje piškotkov na naši spletni strani. </td>
              </tr>

              <tr>
                <td>.rubiconproject.com</td>
                <td>rpb</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td>.rubiconproject.com</td>
                <td>put_2676</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td>.pixel.rubiconproject.com</td>
                <td>rpx</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td>.rubiconproject.com</td>
                <td>khaos</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td>.rubiconproject.com</td>
                <td>put_2307</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td><a href="https://www.t-2.net" target="_blank" class="underline-hover">www.t-2.net</a> </td>
                <td>_hjClosedSurveyInvites</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>

              <tr>
                <td>.t-2.net</td>
                <td>t2u</td>
                <td>Skupni piškotek vseh portalov t-2, za spremljanje prijavljenega uporabnika med horizontom in klubom.</td>
              </tr>
              <tr>
                <td>klub.t-2.net</td>
                <td>ASP.NET_SessionId</td>
                <td>Nujni piškotek na naši spletni strani ki anonimizirano ločuje uporabnike enega od drugega in s tem omogoča prijavo v in uporabo različnih storitev.</td>
              </tr>
              <tr>
                <td>klub.t-2.net</td>
                <td>cart</td>
                <td>Nujni piškotek za delovanje nakupne košarice v naši T-2 Klub spletni trgovini, ki omogoča generiranje spletne košarice in varen zaključek posamezne transakcije. </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

          <h3>2. Piškotki spletne analitike</h3>
          <p>Z njimi analiziramo promet na naši strani. Beležimo obisk, oglede strani, trajanje obiska ipd. Vse s ciljem, da lahko izboljšamo tudi vašo uporabniško izkušnjo.</p>


          <v-simple-table class="simpleTable responsiveTable">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" style="width:250px">
                  Domena ki namešča piškotek
                </th>
                <th>Ime piškotka</th>
                <th>Opis piškotka
                </th>
              </tr>

              </thead>
              <tbody>
              <tr>
                <td>.facebook.com</td>
                <td>fr</td>
                <td>Piškotek za socialno omrežje Facebook, ki omogoča oglaševanje.</td>
              </tr>
              <tr>
                <td>.youtube.com</td>
                <td>YSC</td>
                <td>Piškotek za družbeno omrežje YouTube, ki omogoča anonimizirano beleženje interakcije z video posnetki. </td>
              </tr>
              <tr>
                <td>.youtube.com</td>
                <td>VISITOR_INFO1_LIVE</td>
                <td>Piškotek za družbeno omrežje YouTube, ki omogoča prepoznavanje uporabnikov, ki si ogledajo video medtem ko so prijavljeni v svoj YouTube oziroma Google profil. </td>
              </tr>
              <tr>
                <td>.youtube.com</td>
                <td>PREF</td>
                <td>Piškotek za družbeno omrežje YouTube, ki omogoča anonimizirano beleženje interakcije z video posnetki. </td>
              </tr>
              <tr>
                <td><a href="https://www.t-2.net" target="_blank" class="underline-hover">www.t-2.net</a> </td>
                <td>has_js</td>
                <td>Nujen piškotek za delovanje spletne strani, ki prepoznava anonimizirane tehnične nastavitve naprave obiskovalca na podlagi katere se prikazujejo elementi in vsebine na spletni strani. </td>
              </tr>
              <tr>
                <td>.doubleclick.net</td>
                <td>test_cookie</td>
                <td>Piškotek za storitev Google AdWords ki prepoznava ali dovolite uporabo piškotkov v vašem brskalniku.</td>
              </tr>
              <tr>
                <td>.doubleclick.net</td>
                <td>IDE</td>
                <td>Piškotek za anonimizirano sledenje interakcijam z različnimi oglasi družbe DoubleClick Inc. in Google Inc. </td>
              </tr>
              <tr>
                <td>.yahoo.com</td>
                <td>B</td>
                <td>Piškotek podjetje Yahoo Inc. ki anonimizirano prepoznava posamezne uporabnike spletne strani za potrebe spletne analitike.</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>


          <h3>3. Piškotki družbenih omrežij in oglaševanja</h3>
          <p>V to skupino sodijo piškotki naših partnerskih storitev, kot so: Facebook, Twitter in Google idr. Njihova uporaba vam omogoča tudi, da se spletna izkušnja prilagodi vašemu dosedanjemu načinu uporabe storitev na naši spletni strani.</p>

          <v-simple-table class="simpleTable responsiveTable">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" style="width:250px">
                  Domena ki namešča piškotek
                </th>
                <th>Ime piškotka</th>
                <th>Opis piškotka
                </th>
              </tr>

              </thead>
              <tbody>
              <tr>
              <tr>
                <td>.t-2.net</td>
                <td>_ga</td>
                <td>Piškotek, ki med seboj razlikuje uporabnike na spletni strani.</td>
              </tr>
              <tr>
                <td>.t-2.net</td>
                <td>_gid</td>
                <td>Piškotek s katerim anonimizirano razlikujemo uporabnike na spletni strani med seboj in se hrani le 24 ur.&nbsp;</td>
              </tr>
              <tr>
                <td>.t-2.net</td>
                <td>_gat_UA-23571528-2</td>
                <td>Piškotek, ki omeji število zahtevkov narejenih preko Googla ter ne beleži nobenih informacij o uporabniku.</td>
              </tr>
              <tr>
                <td>klub.t-2.net</td>
                <td>brez imena, (noponotification)</td>
                <td>Piškotek, ki si anonimizirano zapomni, če uporabnik izključi povabilo k dopolnitvi profila uporabnika, ko se ta enkrat prijavi na spletni strani.&nbsp;</td>
              </tr>
              <tr>
                <td>t.sharethis.com</td>
                <td>__pxcel_temp</td>
                <td>Piškotek, ki uporabnikom omogoča deljenje vsebin iz naše spletne strani na družabnih omrežjih.&nbsp;</td>
              </tr>
              <tr>
                <td>.sharethis.com</td>
                <td>__stid</td>
                <td>Piškotek, ki uporabnikom omogoča deljenje vsebin iz naše spletne strani na družabnih omrežjih.&nbsp;</td>
              </tr>
              <tr>
                <td>.google.com</td>
                <td>NID</td>
                <td>- Google - Could cover a multitude of sins but they definitely place cookies if you have embedded Google Maps into your pages.</td>
              </tr>
              <tr>
                <td>accounts.google.com</td>
                <td>GAPS</td>
                <td>Piškotek podjetja Google Inc. ki omogoča uporabnikom prijavljenim v njihove storitve ogled vsebin na naši strani ki so naložene preko portala YouTube ali drugih Googleovih storitev.&nbsp;</td>
              </tr>
              <tr>
                <td>addthis.com</td>
                <td>ouid</td>
                <td>Piškotek vtičnika AddThis, ki omogoča deljenje vsebin iz naše spletne strani na različnih družbenih omrežjih.&nbsp;</td>
              </tr>
              <tr>
                <td>addthis.com</td>
                <td>uid</td>
                <td>Piškotek vtičnika AddThis, ki omogoča deljenje vsebin iz naše spletne strani na različnih družbenih omrežjih.&nbsp;</td>
              </tr>
              <tr>
                <td>addthis.com</td>
                <td>um</td>
                <td>Piškotek vtičnika AddThis, ki omogoča deljenje vsebin iz naše spletne strani na različnih družbenih omrežjih.&nbsp;</td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
          <h3>Nastavitve za nameščanje piškotkov:</h3>
           <p> Z vklopom piškotkov skupin lahko nadzirate, katere piškotke bo spletna stran namestila na vašo napravo. Svojo odločitev boste lahko vedno spremenili.</p>

          <div class="cookiesPage">
            <v-checkbox
                v-model="checkbox"
                :label="`Sistemski piškotki (vedno podeljeni)`"
            ></v-checkbox>
            <v-checkbox
                v-model="checkbox1"
                :label="`Piškotki spletne analitike`"
            ></v-checkbox>
            <v-checkbox
                v-model="checkbox2"
                :label="`Piškotki družbenih omrežij in oglaševanja`"
            ></v-checkbox>
            <v-btn
                color="primary"
                depressed
                class="orangeButtonL"
            >
              SHRANI IN ZAPRI
            </v-btn>
          </div>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Cookies",
  checkbox: true,
  checkbox1: false,
  checkbox2: false,
}
</script>

<style scoped>

</style>