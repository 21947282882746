<template>
  <div class="homeWelcome">
    <div class="top">
      <div class="wrapper">
        <h1>{{ user.name }}, dobrodošli</h1>
        <hr>
        <!--- tekst 1 <p>Hvala, da ste se odločili za naročilo T-2 TV pretočne televizije. Kar en mesec boste lahko uživali ob brezplačnem ogledu vrhunskih TV vsebin. Prijeten ogled želimo!</p>-->
        <p>Na tej strani boste našli navodila za ogled T-2 TV ali enostavno upravljajte s svojo naročnino. </p>
      </div>
    </div>
    <div class="wrapper bottom">
      <hr class="black">
      <h2>Kako naprej?</h2>
      <div class="flex nextBoxes">
        <div class="box">
          <router-link :to="{ name: 'App_watch'}">
            <div class="icon">
              <v-img
                src="@/images/app/watchTV.svg"
              ></v-img>
            </div>
            <h3>Glejte TV</h3>
            <p>Uživajte v svojih izbranih programih na televizijskem sprejemniku, na računalniku ali na mobilni
              napravi.</p>
            <v-img class="arrow"
                   src="@/images/app/arrow.svg"
            ></v-img>
          </router-link>
        </div>

        <div class="box subscription">
          <router-link :to="{ name: 'App_subscription'}">
            <div class="icon">
              <v-img
                src="@/images/app/subscription.svg"
              ></v-img>
            </div>
            <h3>UPRAVLJAJTE Z NAROČNINO</h3>
            <p>Preglejte svoje pakete in njihovo vsebino, jih spreminjajte ali si oglejte svoja plačila.</p>
            <v-img class="arrow"
                   src="@/images/app/arrow.svg"
            ></v-img>
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    user() {
      return this.$store.getters['user/user'];
    }
  },
}
</script>

<style scoped>

</style>
