<template>
  <div>
      <hr class="black nomargin">
      <div class="searchInput">
        <v-text-field
            v-model="searchQuery"
            placeholder="Vpišite ime"
            clearable
            rounded
            solo
            dense
        ></v-text-field>
      </div>
      <v-expansion-panels accordion>
        <v-expansion-panel
            v-for="item in resultQuery"
            :key="item.id"
        >
          <v-expansion-panel-header>
            <span class="programName">{{item.packageName}}</span></v-expansion-panel-header>
          <v-expansion-panel-content class="marginL">
            <!--<p>{{item.opis}}</p> -->
            <h5 class="sumTitle">Programi</h5>
            <hr class="black">
            <ul class="feature-list">
              <li v-for="(channel, index) in item.channels" :key="index">{{ channel }}</li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
  </div>
</template>

<script>
import additionalPackagesChannels from "../config/additionalPackagesChannels";

export default {
  name: "ProgramsListBrowser",
  components: {},
  computed: {
    resultQuery() {
    if (this.searchQuery) {
      return additionalPackagesChannels.filter(item => {
        return (
          item.packageName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          item.channels.some(channel =>
            channel.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        );
      });
    }
    return additionalPackagesChannels;
  }
  },
  data(){
    return {
      searchQuery: null,
    }
  }
}
</script>

<style scoped>

</style>