<template>
  <v-container>
    <BreadcrumbsApp/>
    <v-row class="layout-2col wrapper">
      <v-col class="descCol">
        <hr>
        <p>Nadgradite ali zamenjate svoj izbran TV paket in mu dodajte dodatne TV vsebine. Zamenjava paketa je enostavna
          in hitra. </p>
        <hr class="wide"/>
        <!--        <p><strong>Vaše brezplačno obdobje se izteče 12.12.2022 - čez 5 dni.</strong></p>-->
        <!--        <v-progress-linear-->
        <!--          color="orange"-->
        <!--          value="25"-->
        <!--          height="8"-->
        <!--          reverse-->
        <!--        ></v-progress-linear>-->
      </v-col>
      <v-col class="selectCol">
        <PackageApp/>

        <!--        <div class="navButtonsVertical">-->
        <!--                    <v-btn-->
        <!--                        color="primary"-->
        <!--                        depressed-->
        <!--                        class="orangeButtonL"-->
        <!--                        :to="{ name: 'App_home'}"-->
        <!--                    >-->
        <!--                      Zamenjajte paket-->
        <!--                    </v-btn>-->
        <!--          <hr class="wide"/>-->
        <!--          <v-btn-->
        <!--            depressed-->
        <!--            class="orangeButtonL outline"-->
        <!--            @click="cancelSubscription"-->
        <!--          >-->
        <!--            Odpovejte naročnino-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadcrumbsApp from "@/components/BreadcrumbsApp";
import PackageApp from "@/components/PackageApp";

export default {
  name: "Subscription",
  components: {PackageApp, BreadcrumbsApp},
}
</script>

<style scoped>

</style>
