<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        class="showMore underline-hover"
        v-bind="attrs"
        v-on="on"
      >Prikaži vseh {{ channels.length }} programov
      </a>
    </template>
    <template v-slot:default="dialog">
      <v-card class="popUp">
        <div class="popUpHeader">
          <h3>Programi V PAKETU {{ packageName }}</h3>
          <a
            class="closeButton"

            @click="dialog.value = false"
          ><span class="warpit_icon_x"></span>
          </a>
          <v-text-field
            v-model="searchQuery"
            placeholder="Vpišite ime"
            clearable
            rounded
            solo
            dense
          ></v-text-field>
        </div>
        <hr class="gradient">
      </v-card>
      <div class="popUpContent">
        <ul>
          <li v-for="(channel, index) in resultQuery" :key="index">{{ channel }}</li>
        </ul>
      </div>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "ProgramList",
  components: {},
  props: ['numOfChannels', 'channels', 'packageName'],
  // props: {
  //   chanels: {
  //     type: Array,
  //     default: []
  //   }
  // },
  data() {
    return {
      searchQuery: null,
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.channels.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.toLowerCase().includes(v));
        });
      }

      return this.channels;
    }
  }
}
</script>

<style scoped>
</style>
