<template>
  <div class="breadcrumbs flex">
    <router-link :to="{ name: 'App_home'}"><span class="warpit_icon_Home"></span></router-link>
    <v-img class="arrow"
           src="@/images/app/arrowBreadcrumbs.svg"
    ></v-img>
    <h1 class="mainTitle">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsApp",
  data() {
    return {
      pageTitles: [
        {pathName: "App_watch", title: "Glejte TV"},
        {pathName: "App_subscription", title: "Naročnine in paketi"},
        {pathName: "App_profile", title: "Moj profil"},
        {pathName: "App_receivers", title: "Moji TV sprejemniki"}
      ],
      title: ""
    }
  },
  mounted() {
    const findPathName = this.pageTitles.find(element => element.pathName === this.$router.currentRoute.name)
    if (findPathName !== undefined) {
      this.title = findPathName.title;
    }
  }
}
</script>

<style scoped>

</style>
