<template>
  <v-container>
    <v-row class="wrapper layout-2col">
      <v-col class="descCol">
        <h1>Izbor paketa in dodatnih programov</h1>
        <hr>
        <p>Izberite svoj paket in mu dodajte dodatne programe, ki jih ta paket ne vključuje, a bi jih želeli
          gledati.
        </p>
        <br/>
        <v-btn
          class="orangeButtonL centerPosition"
          color="primary"
          depressed
          @click="changePaymentType"
        >
          Imam promocijsko kodo
        </v-btn>
      </v-col>
      <v-col class="selectCol">
        <v-tabs
          v-model="tab"
          grow
          centered
          icons-and-text
          class="chooseYourPackage"
        >
          <v-tab v-for="item in packages" :key="item.idStoritevVrstaIzbira">
            {{ item.naziv }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in packages" :key="item.idStoritevVrstaIzbira">
            <PackageTabItem :selectedPackage="item" :allPrograms="getPackagePrograms(item)"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PackageTabItem from "@/components/PackageTabItem";
import packageService from "../../services/packageService";
import packagesAllPrograms from "../../config/packages";
import {purchaseTypes} from "@/enumerations/purchaseTypes";

export default {
  name: "PackageSelectStepOne",
  components: {PackageTabItem},
  data() {
    return {
      packagesAllPrograms,
      tab: 1,
      packages: [],
      defaultPackage: {
        id: -1,
        name: "",
        channels: [],
        features: []
      },
    }
  },
  mounted() {
    this.getPackages();
    this.getAdditionalPackages();
    this.getTimeLapseFunction();
    this.getHorizont();
  },
  methods: {
    getPackages() {
      packageService.marketPackages()
        .then(res => {
          this.packages = res.data;
          this.tab = this.packages.findIndex(element => element.idStoritevVrstaIzbira === 5683);
        });
    },
    getAdditionalPackages() {
      this.$store.dispatch('additionalPackages/getAdditionalPackages');
    },
    getTimeLapseFunction() {
      this.$store.dispatch('timelapseFunction/getTimeLapseFunction');
    },
    getHorizont() {
      this.$store.dispatch('horizont/getHorizont');
    },
    getPackagePrograms(item) {
      return this.packagesAllPrograms.find(el => el.id === item.idStoritevVrstaIzbira) || this.defaultPackage;
    },
    changePaymentType() {
      this.$store.dispatch('user/setPurchaseType', purchaseTypes.Promo);
      this.$router.push({name: "PromoStep_1"});
    }
  },
}
</script>

<style scoped>
</style>
