<template>
  <div>
    <hr>
    <h3 class="margin0">VAŠ TRENUTNI PAKET</h3>
    <span v-if="orderCanceled"
          class="time">IZBRANI PAKET SE BO ZAKLJUČIL DNE {{ ott.naslednjiAvtomatskiBilling.date | moment }}</span>
    <span v-else class="time">VKLOPLJENO DO {{ ott.naslednjiAvtomatskiBilling.date | moment }}</span>
    <div class="packageBox">
      <div class="header" v-if="selectedPackage">

        <!-- image for the new package-->
        <v-img
          width="66"
          class="packageImage"
          src="@/images/app/packageNew.svg"
        ></v-img>

        <!-- change image if it's current package -->
        <v-img
          width="66"
          class="packageImage"
          src="@/images/app/packageCurrent.svg"
        ></v-img>

        <h2 v-if="orderCanceled" style="color: #bd6519; font-size: large">
          <strong>Samodejni preklic je v teku</strong>
          <span></span></h2>
        <h3>{{ selectedPackage.naziv }}<span></span></h3>
        <p>Cena za paket z največjo programsko shemo.</p>
        <span class="price">{{ selectedPackage.cena }} € <span>na mesec</span></span>
        <v-btn
          class="show"
          @click="expand = !expand"
          :ripple="false"
        >
          PODROBNOSTI PAKETA <span :class="['arrow',currentIconClass]"></span>
        </v-btn>
      </div>

      <v-expand-transition>
        <v-card
          v-show="expand"
          class="packageContent"
        >
          <hr class="black marginTopNone">
          <ul class="program-list" v-if="additionalPackages">
            <li v-for="(n, i) in 5" :key="n">{{ additionalPackages.channels[i] }}</li>
          </ul>
          <div v-if="additionalPackages !== null && additionalPackages.channels.length > 0">
            <ProgramList :channels="additionalPackages.channels" :package-name="selectedPackage.naziv"/>
          </div>
          <div class="program-added">
            <h3 class="titleF14">DOKUPLJENI PROGRAMI</h3>
            <ul class="">
              <li v-for="(item, index) in subscriptions" :key="index"><span
                class="name">{{ item.title }}<span class="price">+{{ item.price }} € / mesec </span></span></li>
            </ul>
          </div>

        </v-card>
      </v-expand-transition>
    </div>
    <hr class="wide"/>
    <v-btn
      v-if="!orderCanceled"
      depressed
      class="orangeButtonL outline"
      @click="cancelSubscription"
    >
      Odpovejte naročnino
    </v-btn>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import packageService from '../services/packageService';
import packagesAllPrograms from "../config/packages";
import ProgramList from "@/components/ProgramList";
import moment from "moment";
import subscriptionService from "../services/subscriptionService";
import Confirm from "./Confirm";

export default {
  name: "PackageApp",
  components: {ProgramList, Confirm},
  data: () => ({
    expand: false,
    expand2: false,
    subscriptions: [],
    marketPackages: [],
    selectedPackage: null,
    additionalPackages: null,
  }),
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('D.M.Y');
    }
  },
  computed: {
    currentIconClass() {
      return this.expand ? 'warpit_icon_up' : 'warpit_icon_down';
    },
    ott() {
      return this.$store.getters['user/ottData'];
    },
    orderDataExpire() {
      return Date.parse(this.ott.naslednjiAvtomatskiBilling);
    },
    orderCanceled() {
      return this.ott.narocnine.length === 0;
    }
  },
  mounted() {
    subscriptionService.index()
      .then(res => {
        this.subscriptions = res.data;
      });

    packageService.marketPackages().then((res) => {
      this.marketPackages = res.data;
      this.subscriptions.forEach(item => {
        const found = this.marketPackages.find(element => element.idStoritevVrstaIzbira === item.serviceId);
        if (found !== undefined) {
          this.selectedPackage = found;
          return;
        }
      });
      if (this.selectedPackage === null) {
        return;
      }

      this.additionalPackages = packagesAllPrograms.find(item => item.id === this.selectedPackage.idStoritevVrstaIzbira);
    });
  },
  methods: {
    cancelSubscription() {
      this.$refs.confirm.confirm('Ali ste prepričani, da želite preklicati naročnino?')
        .then(() => {
          subscriptionService.cancel()
            .then(() => {
              location.reload();
            });
        }).catch(() => {
        return;
      });
    }
  }
}
</script>

<style scoped>

</style>
