<template>
  <div>
    <h3>VAŠI PRIJAVNI PODATKI</h3>
    <div class="data" v-if="subscription">
      <span>Uporabniško ime:</span>
      <span><strong>{{ subscription.username }}</strong></span>
      <span>Geslo:</span>
      <span><strong>{{ subscription.password }} </strong></span>
    </div>

  </div>
</template>

<script>
import userService from "../services/userService";

export default {
  name: "WatchTvUserData",
  data() {
    return {
      openPanel: null,
      subscription: null
    }
  },
  mounted() {
    userService.ottData().then((res) => {
      this.subscription = {
        username: res.idPrikljucek,
        password: res.geslo
      };
    });
  }
}
</script>

<style scoped>

</style>
