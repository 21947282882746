import Vue from 'vue';

export default {
    async validate(phoneNumber) {
        const formData = new FormData();
        formData.append("number", phoneNumber);
        const response = await Vue.axios.post('/user/validatePhoneNumber', formData);
        return response.data;
    },
}
