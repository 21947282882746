<template>
  <div class="appSteps">
    <v-container>
      <v-row class="layout-2col wrapper">
        <v-col class="descCol">
          <h1>Povzetek naročila</h1>
          <hr class="responsiveHidden750"/>
          <p v-html="orderSummaryText"></p>
          <!-- c ni besedila -->
        </v-col>
        <v-col class="selectCol">
          <v-simple-table class="simpleTable">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Storitev
                </th>
                <!--<th class="text-right responsiveHidden">
                  CENA BREZ DDV
                </th>-->
                <th class="text-right">
                  CENA Z DDV
                </th>
              </tr>

              </thead>
              <tbody>
              <tr>
                <td>{{ selectedPackage.naziv }}</td>
                <!--<td class="text-right colored responsiveHidden" >{{ selectedPackage.cena }} €</td>-->
                <td class="text-right">{{ selectedPackage.cena }} €</td>
              </tr>
              <tr
                v-for="item in selectedAdditionalPackages"
                :key="item.id"
              >
                <td>{{ item.naziv }}</td>
                <!--<td class="text-right colored responsiveHidden" >brez €</td>-->
                <td class="text-right">{{ item.cena }} €</td>
              </tr>
              <tr v-if="timeLapse !== null">
                <td>{{ timeLapse.naziv }}</td>
                <!--<td class="text-right colored responsiveHidden" >{{ selectedPackage.cena }} €</td>-->
                <td class="text-right">{{ timeLapse.cena }} €</td>
              </tr>
              <tr v-if="horizont !== null">
                <td>{{ horizont.naziv }}</td>
                <!--<td class="text-right colored responsiveHidden" >{{ selectedPackage.cena }} €</td>-->
                <td class="text-right">{{ horizont.cena }} €</td>
              </tr>
              </tbody>
              <tfoot>
              <tr class="sumPrice">
                <th>Skupaj</th>
                <!--<th class="text-right colored responsiveHidden">12.00 €</th>-->
                <th class="text-right">{{ totalPrice }} €</th>
              </tr>
              <tr class="price">
                <th>Za plačilo</th>
                <!--<th class="responsiveHidden">&nbsp;</th>-->
                <th class="text-right">{{ totalPayPrice }} €</th>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <p class="payment" v-html="paymentSummaryText"></p>
          <!-- c
          <p class="payment">S potrditvijo naročila in izvedena plačila, bomo vklopili vse izbrane storitve, ki bodo aktivne do nadaljnjega. Naslednje plačilo storitev se bo izvedlo 3.2.2022.
          <span class="monthly-payment">Plačilo se izvede mesečno.</span>></p>-->
          <hr class="gradient"/>
          <div class="navButtons">
            <router-link :to="{ name: 'Package_select_step_4'}" class="underline-hover">Prejšnji korak</router-link>
            <v-btn
              color="primary"
              depressed
              class="orangeButtonL"
              @click="addSubscription"
            >
              NAROČITE
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import userService from '../../services/userService';

export default {
  name: "PackageSelectStepFive",
  components: {},
  computed: {
    ...mapGetters({
      selectedPackage: 'offer/getSelectedPackage',
      selectedAdditionalPackages: 'offer/getSelectedAdditionalPackages',
      timeLapse: 'offer/getTimeLapseFunction',
      horizont: 'offer/getHorizont',
      totalPrice: 'offer/getTotalPrice',
      totalPayPrice: 'offer/getTotalPayPrice',
    }),
    orderSummaryText() {
      return "Prvi mesec podarimo največji programski paket Maxi in TV Časovne funkcije.";
    },
    paymentSummaryText() {
      return this.totalPayPrice === 0 ?
        `S potrditvijo naročila bomo vklopili izbrane storitve, ki bodo aktivne do nadaljnjega. Paket T-2 TV Maxi in Časovni zamik bosta brezplačno vklopljena do dne ${this.paymentDate(1)}.<br> V primeru preklica znotraj brezplačnega obdobja, se plačilo ne bo izvedlo. <span class="monthly-payment">Plačilo se izvede mesečno.</span>`:
        `S potrditvijo naročila in izvedenega plačila bomo vklopili vse izbrane storitve, ki bodo aktivne do nadaljnjega. Paket T-2 TV Maxi in Časovni zamik bosta brezplačno vklopljena do dne ${this.paymentDate(1)}.<span class="monthly-payment">Plačilo se izvede mesečno.</span>`
    },
  },
  data() {
    return {}
  },
  methods: {
    paymentDate(months) {
      const date = new Date();
      date.setMonth(date.getMonth() + months);
      return date.toLocaleDateString();
    },
    async addSubscription() {
      await this.$store.dispatch('user/getUser')
        .then(() => {
          if (this.$store.getters['user/subscriptions'].length !== 0) {
            this.$router.push({name: 'App_home'});
            return
          }
        });

      let selectedAdditionalPackagesIDs = [];
      this.selectedAdditionalPackages.forEach(element => {
        selectedAdditionalPackagesIDs.push(element.id);
      });
      selectedAdditionalPackagesIDs.push(this.selectedPackage.idStoritevVrstaIzbira);

      if (this.horizont !== null) {
        selectedAdditionalPackagesIDs.push(this.horizont.idStoritevVrstaIzbira);
      }

      if (this.timeLapse !== null) {
        selectedAdditionalPackagesIDs.push(this.timeLapse.id);
      }

      const formData = new FormData();
      selectedAdditionalPackagesIDs.forEach(element => {
        formData.append("storitve[]", element);
      });
      formData.append("successRedirectUri", window.PAYMENT_SUCCESS_URL || 'localhost:8080/payment-done');
      formData.append("cancelRedirectUri", window.PAYMENT_ERROR_URL || 'localhost:8080/package-select/step-five');

      userService.createSubscription(formData)
        .then((res) => {
          window.location.href = res.paymentRedirectUrl;
        });
    }
  }
}
</script>

<style scoped>

</style>
