<template>
  <v-container>
    <div>
      <BreadcrumbsApp/>
      <v-row class="layout-2col wrapper">
        <v-col class="descCol">
          <hr>
          <p>Na tem mestu dodajate in odvzemate svoje registrirane TV sprejemnike ali druge pametne naprave. Sprejemnik
            dodate tako, da na TV sprejemniku odprete aplikacijo T-2 TV in v polje na tej spletni strani vpišete kodo,
            ki je prikazana na TV zaslonu.
          </p>
        </v-col>
        <v-col class="selectCol recieversList">
          <hr>
          <h3>VAŠI TRENUTNI TV SPREJEMNIKI</h3>
          <ul v-for="(receiver, index) in receivers" :key="index">
            <li><span>{{ receiver.deviceName }}</span>
              <a @click.prevent="deleteReceiver(receiver)"
                 class="deleteIcon"><span
                class="warpit_icon_trash"></span></a>
            </li>
          </ul>
          <v-dialog
            v-model="dialog"
            persistent
            v-if="receivers.length < maxReceivers"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                class="orangeButtonL"
                v-bind="attrs"
                v-on="on"
                v-if="receivers.length < maxReceivers"
              >
                DODAJTE NOV TV SPREJEMNIK
              </v-btn>

            </template>
            <v-card class="popUp form">
              <div class="popUpHeader">
                <h3 class="margin20">DODAJANJE TV SPREJEMNIKA</h3>
                <a
                  class="closeButton"
                  @click="dialog = false"
                >
                  <span class="warpit_icon_x"></span>
                </a>
              </div>
              <hr class="gradient">
              <v-card-text>
                <v-container>
                  <p>Prosimo, vpišite kodo, ki ste jo pridobili na svojem TV sprejemniku od aplikacije T2 TV.</p>
                  <v-form
                    ref="addReceiver"
                    v-model="valid"
                    lazy-validation
                    class="form"
                  >
                    <v-text-field
                      :rules="codeRules"
                      label="Koda"
                      v-model="newReceiverCode"
                      required
                    ></v-text-field>
                  </v-form>

                </v-container>
              </v-card-text>
              <hr class="gradient margin">
              <v-card-actions class="buttons">
                <v-btn
                  class="primary saveButton"
                  text
                  @click="addReceiver"
                >
                  DODAJTE TV SPREJEMNIK
                </v-btn>
                <a
                  class="cancelButton underline-hover"
                  @click="dialog = false"
                >
                  Prekličite
                </a>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <confirm ref="confirm"></confirm>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbsApp from "@/components/BreadcrumbsApp";
import receiverService from "../../services/receiverService";
import Confirm from "../../components/Confirm";

export default {
  name: "MyReceivers",
  components: {Confirm, BreadcrumbsApp},
  data() {
    return {
      dialog: false,
      valid: true,
      codeRules: [
        v => !!v || 'Prosimo vnesite kodo.',
      ],
      receivers: [],
      newReceiverCode: null,
      maxReceivers: 1
    }
  },
  computed: {
    ott() {
      return this.$store.getters['user/ottData'];
    },
  },
  mounted() {
    this.getReceivers();
  },
  methods: {
    getReceivers() {
      if (!this.ott) {
        return;
      }

      if (this.ott.narocnine.find(item => item.idStoritve === 5683)) {
        this.maxReceivers = 2;
      }

      receiverService.index(this.ott.idPrikljucek)
        .then(res => {
          this.receivers = res.data;
        });
    },
    addReceiver() {
      if (!this.$refs.addReceiver.validate()) {
        return;
      }

      const formData = new FormData();
      formData.append("subscriberId", this.ott.idPrikljucek);
      formData.append("authorisationToken", this.newReceiverCode);

      receiverService.store(formData)
        .then(() => {
          this.dialog = false;
          this.getReceivers();
          this.newReceiverCode = '';
        });
    },
    deleteReceiver(item) {
      this.$refs.confirm.confirm('Ali ste prepričani, da želite odstraniti pametno napravo za uporabo aplikacije T-2 TV?')
        .then(() => {
          const formData = new FormData();
          formData.append("subscriberId", this.ott.idPrikljucek);
          formData.append("deviceId", item.id);

          receiverService.destroy(formData)
            .then(() => {
              this.getReceivers();
            });
        }).catch(() => {
        return;
      });
    },
  }
}
</script>

<style scoped>

</style>
