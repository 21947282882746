<template>
  <div class="instructions">
    <h3>KAKO SE LOTITI TEGA?</h3>
    <ul>
      <li>
        <span class="number">1</span>
        <p>V meniju televizorja ali multimedijske naprave poiščite trgovino z aplikacijami in namestite aplikacijo T-2 TV. </p>
      </li>
      <li>
        <span class="number">2</span>
        <p>S klikom na gumb 'Dodajte nov TV sprejemnik' odprite stran za registracijo naprav in dodajte naprave na katerih želite spremljati T-2 TV. </p>
      </li>
      <li>
        <span class="number">3</span>
        <p>Udobno se namestite in uživajte ob ogledu! </p>
      </li>
    </ul><br><br>
  </div>
</template>

<script>
export default {
  name: "WatchTvInstructions"
}
</script>

<style scoped>

</style>