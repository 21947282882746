<template>
  <v-container>
    <v-row class="layout-2col wrapper links-underline">
      <v-col class="descCol">
        <h3>Splošni pogoji uporabe storitev »T-2 TV v tujem omrežju«</h3>

      </v-col>
      <v-col class="selectCol">
        <div>
          <h3>I. Uvodne določbe</h3>
          <p>T - 2, d. o. o., Verovškova ulica 64a, 1000 Ljubljana (v nadaljevanju: T-2) v okviru svoje gospodarske dejavnosti ponuja možnost uporabe storitve »T-2 TV v tujem omrežju«.</p>
          <p>S temi splošnimi pogoji so določene lastnosti in pogoji uporabe storitve T-2 TV v tujem omrežju.</p>
          <p>Ti splošni pogoji se uporabljajo ob subsidiarni uporabi Splošnih pogojev poslovanja družbe T-2, Splošnih pogojev za uporabo storitev IP TV, Splošnih pogojev obdelave osebnih podatkov v družbi T-2 d.o.o., Posebnim dogovorom – Pogoji uporabe storitev v aplikaciji T-2 tv2go, Splošnimi pogoji poslovanja za sklepanje pogodb na daljavo ter ob hkratni uporabi veljavne zakonodaje.</p>
          <p>Storitev T-2 TV v tujem omrežju je namenjena vsem fizičnim in pravnim osebam, ki izpolnjujejo pogoje za sklenitev naročniškega razmerja in pogoje za uporabo storitve T-2 TV v tujem omrežju (v nadaljevanju: naročniki).</p>
          <p>Vse dodatne informacije, ceniki ter splošni pogoji so dostopni na spletni strani <a href="https://www.t-2.net/" target="_blank">www.t-2.net</a> ter spletni strani <a href="www.t-2.net" target="_blank">www.t-2.net</a>, telefonski številki za pomoč uporabnikom 064 064 064 ter v poslovalnicah T-2. </p>

          <hr />
          <h3>II. Opis ponudbe</h3>
          <p>T-2 TV v tujem omrežju je storitev, ki naročnikom ob sklenitvi naročniškega razmerja omogoča ogled izbranih televizijskih programov v katerem koli internetnem omrežju v Republiki Sloveniji ter v slovenskih mobilnih omrežjih. V primeru potovanja v tujino, pa T-2 skladno z Uredbo o čezmejni prenosljivosti omogoči naročnikom uporabo tudi zunaj Republike Slovenije, ampak še vedno znotraj EU in/ali EGP. </p>
          <p>Storitev T-2 TV v tujem omrežju naročnikom omogoča naslednje storitve: </p>
          <ul class="listPage">
            <li>spremljanje TV sporedov TV programov,</li>
            <li>nastavljanje seznama priljubljenih TV programov iz programske ponudbe T-2, </li>
            <li>pregled programske ponudbe T-2 po žanrih in jezikih TV programov, </li>
            <li>nastavljanje opomnikov za posamezne oddaje TV programov iz programske ponudbe T-2,</li>
            <li>možnost ogleda izbranih TV programov*,</li>
          </ul>
          <p>*Naročniku bo v okviru storitve T-2 TV v tujem omrežju omogočeno spremljanje le tistih programov, ki so navedeni v vsakokratni veljavni ponudbi T-2. Programska shema programov, ki so zagotovljeni v okviru storitve T-2 TV v tujem omrežju, se lahko skladno s splošnimi pogoji T-2 kadarkoli spremeni.</p>
          <p>T-2 lahko v okviru svoje ponudbe naročnikom v okviru storitve T-2 TV v tujem omrežju ponudi tudi dodatne storitve za doplačilo, kot npr. uporabo časovnih funkcij, TVOD, SVOD ipd. Informacije in ceniki take dodatne ponudbe so razvidni na spletni strani <a href="https://www.t-2.net" target="_blank">www.t-2.net</a>.</p>

          <hr />
          <h3>III. Pogoji naročila in uporabe storitev T-2 TV v tujem omrežju</h3>
          <p>Uporaba storitev T-2 TV v tujem omrežju je možna na izbranih televizijskih sprejemnikih, preko aplikacije T-2 TV, na osebnih računalnikih, z uporabo storitve T-2 tv2go na spletni strani <a href="https://tv2go.t-2.net/ " target="_blank">https://tv2go.t-2.net/ </a> ali na tabličnih računalnikih in pametnih mobilnih telefonih, z namestitvijo aplikacije T-2 tv2go na spletnih trgovinah: Google Play, Samsung Apps ali Apple App Store (v nadaljevanju: naprave). </p>
          <p>Naprave morajo ustrezati vsakokratnim minimalnim tehničnim in programskim zahtevam. Naročnik je pred naročilom storitve T- 2 TV v tujem omrežju dolžan sam preveriti tehnično ustreznost svojih naprav, s klicem na številko 064 064 064, ali z obiskom katere izmed poslovalnic T-2. </p>
          <p>Ogled vsebin storitve T-2 TV v tujem omrežju ni možen preko tehnologij, ki skrivajo izvorni IP naslov (VPN strežniki, Proxy strežniki ipd.).</p>
          <p>Za delovanje storitev T-2 TV v tujem omrežju je potrebna internetna povezava s hitrostjo prenosa podatkov min. 512 kbit/s. Naročnik si je za delovanje storitev T-2 TV v tujem omrežju dolžan sam in na lastne stroške zagotoviti dostop do svetovnega spleta. </p>
          <p>Naročnik je seznanjen z dejstvom, da je za uporabo storitev T-2 TV v tujem omrežju internetni dostop oziroma prenos podatkov mobilnem omrežju plačljiv in da se obračunava skladno s cenikom operaterja, oziroma mobilnega operaterja. </p>
          <p>Število naprav, na kateri je možna sočasna uporabe storitve T-2 TV v tujem omrežju je odvisno od izbranega paketa oziroma izbrane ponudbe T-2. </p>
          <p>Za uporabo storitve T-2 TV v tujem omrežju, se mora naročnik v aplikaciji identificirati z dodeljenim uporabniškim imenom in geslom. Naročnik se uporabniško ime in geslo zavezuje varovati skrbno pred tretjimi osebami tako, da je tretjim osebam onemogočena nepooblaščena raba oziroma zloraba uporabniškega imena in gesla naročnika. Naročnik odgovarja za vse posledice in škodo, ki nastanejo zaradi zlorabe oziroma nepooblaščene uporabe teh podatkov in sicer v primerih, ko ni uporabil vseh razumnih ukrepov za zaščito teh podatkov. T-2 je v takih primerih upravičen preprečiti uporabo storitev oz. odpovedati naročniško razmerje. </p>
          <p>Naročnik sme uporabljati storitev T-2 TV v tujem omrežju za zakonite, moralne in s temi splošnimi pogoji opredeljene namene in na način, skladen z določbami veljavne zakonodaje, temi splošnimi pogoji in splošnim pogojem, ki veljajo subsidiarno. V nasprotnem primeru je naročnik oziroma uporabnik odgovoren za vso škodo, ki nastane družbi T-2, njenim pogodbenim partnerjem ali tretjim osebam, T-2 pa je upravičen preprečiti uporabo storitev oz. odpovedati naročniško razmerje.</p>
          <hr />
          <h3>IV. Mesečna naročnina</h3>
          <p>Uporaba storitve T-2 TV v tujem omrežju je plačljiva. Višina mesečne naročnine je odvisna od izbranega paketa oziroma ponudbe T-2, in je opredeljena v vsakokraten veljavnem ceniku T-2, ki je objavljen na spletni strani <a href="https://www.t-2.net" target="_blank">www.t-2.net</a>. </p>
          <p>Mesečna naročnina uporabe storitve T-2 TV v tujem omrežju zajema:</p>

          <ul class="listPage">
            <li>programsko shemo, kot je za storitev T-2 TV v tujem omrežju določena v vsakokratni veljavni ponudbi T-2,</li>
            <li>aplikacijo T-2 TV (odvisno od izbranega paketa)</li>
            <li>storitev tv2go.</li>
          </ul>

          <p>Možnost sočasne uporabe storitev na dodatnih televizijskih sprejemnikih (tj. dodatne registracije televizijskih sprejemnikov), je možna z naročilom dodatnih aplikacij T-2 TV, ki se zaračunajo skladno z vsakokratnim veljavnim cenikom, dostopnim na spletni strani <a href="https://www.t-2.net" target="_blank">www.t-2.net</a>. Ne glede na naročeno število aplikacij T-2 TV je sočasna uporaba storitev T-2 TV v tujem omrežju omejena na število, kot je določeno v naročenem paketu storitve T-2 TV v tujem omrežju.</p>
          <p>V mesečni naročnini uporabe storitve T-2 TV v tujem omrežju ni zajeta storitev časovnih funkcij (časovni zamik), TVOD, SVOD, in morebitnih drugih storitev, ki se vse zaračunavata skladno z vsakokratnim veljavnim cenikom, dostopnim na spletni strani <a href="https://www.t-2.net" target="_blank">www.t-2.net</a>. </p>
          <p>V mesečni naročnini prav tako ni zajeta naročnina na morebitne dodatno naročene TV pakete, skladno z vsakokratno veljavno ponudbo.</p>
          <p>Prenos podatkov, ki je potreben za uporabo storitve T-2 TV v tujem omrežju, ni vključen v mesečno naročnino.</p>

          <p>Glede izdajanja računov, fikcije prejema računa, dolžnosti in načinov plačila, jamstev naročnika za uporabnika ter pobota se smiselno uporablja 25. člen Splošnih pogojev poslovanja družbe T-2.</p>
          <p>V primeru neplačila računov za naročene storitve T-2 TV v tujem omrežju se glede začasnega in trajnega izklopa smiselno uporabljajo določbe 24., 28. in 29. člena Splošnih pogojev poslovanja družbe T-2. V primeru začasnega izklopa storitev je naročniku onemogočen dostop do vseh storitev T-2 TV v tujem omrežju.</p>

          <hr />
          <h3>V. Naročilo na storitev T-2 TV v tujem omrežju</h3>
          <p>Naročnik se lahko na storitev T-2 TV v tujem omrežju naroči na več načinov, in sicer:</p>
          <ul class="listPage">
            <li>s klicem na klicni center T-2 (številka 064 064 064), </li>
            <li>v poslovalnicah T-2, </li>
            <li>preko servisnih strani Horizont (spletni naslov: https://horizont.t-2.net), </li>
            <li>na televizijskem sprejemniku preko aplikacije T-2 tv2go. </li>
          </ul>
          <p>Naročnik mora pred sklenitvijo naročniškega razmerja za storitev T-2 TV v tujem omrežju soglašati z določili teh splošnih pogojev ter vseh v 1. členu navedenih splošnih pogojev. </p>
          <p>Za sklenitev pogodbe na daljavo veljajo določila Splošnih pogoji poslovanja za sklepanje pogodb na daljavo, dostopnih na <a href="https://www.t-2.net/splosni-pogoji-podjetja" target="_blank">https://www.t-2.net/splosni-pogoji-podjetja</a>, v katerih je med drugim opredeljena pravica naročnika - potrošnika do odstopa od pogodbe. </p>

          <hr />
          <h3>VI. Prenehanje naročniškega razmerja na storitev T-2 TV v tujem omrežju</h3>
          <p>Naročniška pogodba v okviru katere so naročene storitve T-2 TV v tujem omrežju poleg
primerov, ki jih določajo Splošni pogoji poslovanja družbe T-2, preneha tudi v primeru:</p>
<ul class="listPage">
            <li>prenehanja pravic družbe T-2 za omogočanje uporabe storitve T-2 TV v tujem omrežju, </li>
            <li>naročnikove ali uporabnikove kršitve teh splošnih pogojev in </li>
            <li>v primerih, ko to določa veljavna zakonodaja.</li>
          </ul>
          <p>S prenehanjem naročniškega razmerja naročnik izgubi pravico do dostopa in uporabe storitve T-2 TV v tujem omrežju. </p>
          <p>Družba T-2 ima pravico, da naročniku brez predhodnega opozorila začasno ali trajno prepove uporabo storitve T-2 TV v tujem omrežju:</p>
          <ul class="listPage">
            <li>če je način uporabe storitve T-2 TV v tujem omrežju v nasprotju s temi splošnimi pogoji oziroma splošnimi pogoji, ki se uporabljajo subsidiarno, oziroma veljavno zakonodajo ali </li>
            <li>v kolikor uporaba ali način uporabe storitve T-2 TV v tujem omrežju utegne pomeniti tehnična in/ali komercialna tveganja za zagotavljanje telekomunikacijskih storitev družbe T-2.</li>
          </ul>
          <hr />
          <h3>VII. Zaščita vsebine in pravice intelektualne lastnine</h3>
          <p>Storitev T-2 TV v tujem omrežju, lahko naročnik uporablja zgolj za privatno uporabo, predvajanih vsebin pa ne sme izročati ali priobčiti javnosti, prav tako s primerki del ne sme dosegati neposredne ali posredne gospodarske koristi.</p>
          <p>Naročnik jamči, da ne bo kršil avtorskih, sorodnih in drugih pravic družbe T-2, njenih pogodbenih partnerjev ali tretjih oseb ipd. in je v primeru kršitve odgovoren za vso škodo, ki nastane družbi T-2, njenim pogodbenim partnerjem ali tretjim osebam.</p>
          <p>Naročnik je seznanjen, da storitev T-2 TV v tujem omrežju uporablja tehnologijo za zaščito vsebine storitve T-2 TV v tujem omrežju. V kolikor trenutna tehnologija ne more zaščititi vsebine, je potrebno navedeno tehnologijo nadgraditi. V času do ustrezne nadgradnje tehnologije lahko pride do omejene ali onemogočene dostave vsebine storitve na določene registrirane naprave. Naročnik se zavezuje, da bo aplikacije storitve T-2 TV v tujem omrežju nadgradil ob vsakem pozivu za nadgradnjo, v nasprotnem primeru T-2 ne odgovarja za moten oziroma onemogočen dostop do storitve T-2 TV v tujem omrežju.</p>
          <p>Naročnik s sklenitvijo naročniške pogodbe ne pridobi nikakršnega lastništva na storitvi T-2 TV v tujem omrežju.</p>
          <p>S sklenitvijo naročniškega razmerja naročniki pridobijo zgolj pravico do dostopa in pravico do zasebne ter nekomercialne uporabe storitve T-2 TV v tujem omrežju. Pravica do dostopa in uporabe storitve T-2 TV v tujem omrežju je osebna in neprenosljiva. Dostop in uporaba storitve T-2 TV v tujem omrežju ne pomeni prenosa kakršnihkoli ostalih pravic intelektualne lastnine ali drugih pravic v zvezi s storitvijo T-2 TV v tujem omrežju na naročnika ali uporabnika.</p>

          <hr />
          <h3>VIII. Varovanje osebnih podatkov </h3>
          <p>Obdelava osebnih podatkov s strani T-2 kot upravljavca poteka v skladu z dokumentom - Informacijami o obdelavi osebnih podatkov v družbi T-2, d.o.o., dostopnih na <a href="https://www.t-2.net/varnost-osebnih-podatkov" target="_blank">https://www.t-2.net/varnost-osebnih-podatkov</a>. </p>
          <p>T-2 ne hrani podatkov kreditne kartice, ampak za to skrbi certificiran plačilni posrednik Monri d.o.o. (Hrvaška) - Monri  - Member of Payten | Radnička cesta 54, 10000 Zagreb, Croatia. </p>



          <hr />
          <h3>IX. Zagotavljanje storitev</h3>
          <p>T-2 ponuja storitev T-2 TV v tujem omrežju od 00.00 do 24.00 ure, sedem (7) dni v tednu, vse dni v letu. V primeru nedelovanja storitev bo T-2 k odpravljanju napak pristopil v najkrajšem možnem času.</p>
          <p>Družba T-2 ni dogovorna za prekinitve in napake oziroma nedelovanje, če je le-to posledica nujnih vzdrževalnih del, naključja ali dogodkov, ki niso pod njeno kontrolo oziroma na katere ne more vplivati (v teh primerih lahko družba T-2 onemogoči uporabo storitve T-2 TV v tujem omrežju tudi brez opozorila). Prav tako družba T-2 ne odgovarja za nepravilnosti, nastale zaradi uporabe neustreznih naprav ali ravnanja naročnika ali uporabnika; za škodo, ki nastane zaradi neprimerne naprave, oziroma nepravilne ali neustrezne uporabe naprave; varovanje in ravnanje z uporabniškim imenom in/ali geslom in/ali za posledično zlorabo uporabniškega imena in gesla, za naročnikovo oziroma uporabnikovo nezakonito ali protipredpisno uporabo storitve T-2 TV v tujem omrežju ter v vseh drugih primerih, ko lahko v poštev pride smiselna uporaba zadnjega odstavka 11. člena Splošnih pogojev poslovanja družbe T-2 d.o.o.</p>
          <p>Družba T-2 v primerih, navedenih v predhodnem odstavku, ne jamči za kakršnokoli morebitno nastalo škodo.</p>
          <hr />
          <h3>X. Končne določbe</h3>
          <p>T-2 si pridržuje pravico, da v skladu z 31. členom Splošnih pogojev poslovanja družbe T-2 spremeni splošne pogoje in pogoje naročniške pogodbe. </p>
          <p>Naročnik lahko prejme določila teh splošnih pogojev tudi na papirju, in sicer na podlagi pisne zahteve, ki jo na družbo T-2 pošlje po pošti ali po elektronski pošti ali na podlagi telefonskega klica na telefonski številki klicnega centra <a href="tel:064 064 064" target="_blank">064 064 064</a>. Družba T-2 mu bo določila teh splošnih pogojev poslala po pošti ali izročila ob obisku naročnika v poslovalnici T-2.</p>
          <p>V Ljubljani, dne 7. 7. 2023</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>